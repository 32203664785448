import React from 'react'
import { Container, Card } from 'react-bootstrap';
import '../style.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Row from 'react-bootstrap/Row'
import useAppContext from '../utilities/use-app-context';
import { useHistory } from 'react-router-dom';

type QueryProps = {
  queryData?:any
  solutionDetails?:any
}
const QueryDetail = (props: QueryProps) => {
  const querydata = (props.queryData && props.queryData.entitlements && props.queryData.entitlements.length>0) ? props.queryData.entitlements.sort((a:any, b:any) => a.entitlementName.localeCompare(b.entitlementName)) : null
  const { config, currentUser } = useAppContext();
  const history = useHistory()
  const responsive ={
    superLargeDesktop: {
      breakpoint: { max: 3840, min: 2551 },
      items: 4
    },
    ultraHighDefinationDesktop: {
      breakpoint: { max: 2550, min: 1921 },
      items: 3
    },
   fullLargeDesktop: {
      breakpoint: { max: 1920, min: 1441 },
      items: 3
    },
    largedesktop: {
      breakpoint: { max: 1440, min: 1367 },
      items: 3
    },
    smalldesktop: {
      breakpoint: { max: 1366, min: 1281 },
      items: 2
    },
    Desktop: {
      breakpoint: { max: 1280, min: 1025 },
      items: 2
    },
    largeTablet: {
      breakpoint: { max: 1024, min: 800 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 801, min: 651 },
      items: 2
    },
    largeMobile: {
      breakpoint: { max: 650, min: 465 },
      items: 1
    },
    smallMobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
 
const redirectToSQLEditor = () => {
  history.push('/queries/new')
}
const RedirectToDirectIntegration = () => {
  history.push('/directintegration')
}

const runQuery = (queryId:any) => {
  if(queryId){
    localStorage.setItem('subscriptionCode', JSON.stringify(props.solutionDetails.subscriptionCode))
    history.push(`/queries/${queryId}?solutionpack=true&run=true`)
  }else{
    
  }
}
  return (
    <Container className="query-container" >
      <div className='query-head'>
        Query the Data
      </div>
      <div className='header-caption'>
        Try one of our pre-built SQL queries to sample the capabilities of this Solution Pack. These will take you directly to
        <span className='editor' onClick={redirectToSQLEditor}> our SQL editor </span> where you can run the selected query or write your own queries.
      </div>
     {querydata && <Carousel className='query-carousel' responsive={responsive}>

      {querydata?.sort((a: any, b: any) => a.entitlementName.localeCompare(b.entitlementName))?.map((item: any, i:any) => (
            <Card key={i} className="card-query-style" >
         <div className='query-download'>
            <img className='queryImg' src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/home-page/sql.svg'} />
            { item.downloadFile === true ? <div  onClick={() => runQuery(item.queryId)} className='query-run-start'><img className='play' src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/home-page/play.svg'} /><span className='runButton'>Run</span></div>:''}
        </div>
          <Card.Body >
            <Card.Title className="card-title">{item.entitlementName}</Card.Title>
            <Card.Text className="card-text">
              {item.entitlementDescription}
            </Card.Text>
          </Card.Body>
        </Card>))} 


      </Carousel>}
      <div className="solution-para-text">To access our database using your own SQL editor, see <span className="editor" onClick={RedirectToDirectIntegration}>Direct Integration.</span>
      </div>
    </Container>
  )
}

export default QueryDetail







