import React from 'react'
import { Container, Card } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../style.css'
import Row from 'react-bootstrap/Row'
import useAppContext from '../utilities/use-app-context';
import DataElementsLink from './DataElementsLink';

type divedeeper = {
    divedeeper?: any
    dataElements?: any
}

const DiveDeeper = (props:divedeeper) => {

    const { config, currentUser } = useAppContext();
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 3840, min: 2551 },
        items: 4
      },
      ultraHighDefinationDesktop: {
        breakpoint: { max: 2550, min: 1921 },
        items: 3
      },
     fullLargeDesktop: {
        breakpoint: { max: 1920, min: 1441 },
        items: 3
      },
      largedesktop: {
        breakpoint: { max: 1440, min: 1367 },
        items: 3
      },
      smalldesktop: {
        breakpoint: { max: 1366, min: 1281 },
        items: 2
      },
      Desktop: {
        breakpoint: { max: 1280, min: 1025 },
        items: 2
      },
      largeTablet: {
        breakpoint: { max: 1024, min: 800 },
        items: 2
      },
      tablet: {
        breakpoint: { max: 801, min: 651 },
        items: 2
      },
      largeMobile: {
        breakpoint: { max: 650, min: 465 },
        items: 1
      },
      smallMobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };

      const downloadFile = async (type:any,file:any,from:any) => {
        console.log(type)
        if(type=="Excel" && from=='btn'){
          return window.open(
            `${config.publicUrl}/api/downloads?type=${type}&file=${file}`,
            '_blank'
          );
        }else if(type=="PDF" && from=='card')
        {
          return window.open(
            `${config.publicUrl}/api/downloads?type=${type}&file=${file}`,
            '_blank'
          );
        }
      
      
        }
      
      const divedata = (props.divedeeper && props.divedeeper.entitlements && props.divedeeper.entitlements.length>0)?props.divedeeper.entitlements.sort((a:any, b:any) => a.entitlementName.localeCompare(b.entitlementName)):null
    // console.log(divedata)
      // if(divedata.find((d:any)=> !d.sequenceNumber)){
          //alphabetic
          // console.log('alphabetic')
          // divedata.sort((a:any, b:any) => a.sequenceNumber.localeCompare(b.sequenceNumber))

          
        // }else{
          // sequence
          // divedata.sort((a:any, b:any) => a.sequenceNumber.localeCompare(b.sequenceNumber))
        
      // }
      return (
        <section className="dive-deep">
            <Container className="dive-container" >
                <div className='dive-heading'>
                    Dive Deeper
                </div>
                <div className='dive-headerCaption'>
                    Learn all you ever wanted to know about the property data with our P-Articles (Property Articles).
                </div>
                {divedata &&  <Carousel className="carousel-dive-color" responsive={responsive} >
                  
                 
                {divedata?.sort((a: any, b: any) => a.entitlementName.localeCompare(b.entitlementName))?.map((item: any, i:any) => (<Card key={i} className="card-query-style" >
                                <Card.Img  draggable={false} className="dive-card-img" src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/home-page/solution-pdf.svg'}></Card.Img>
                                <Card.Body >
                                    <Card.Title className="card-title">{item.entitlementName}</Card.Title>
                                    <Card.Text className="card-dive-text">
                                    {item.entitlementDescription}
                                    </Card.Text>
                                    {item.entitlementType === 'PDF' && item.downloadFile === true &&   <div className="dive-download"  onClick={() =>downloadFile(item.entitlementType,item.defaultValue,"card")}><img className='dive-download-img' src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/home-page/veiw.svg'} /><p>View PDF</p></div>}
                                </Card.Body>
                            </Card>))}   
                </Carousel>}
           {props.dataElements &&  <DataElementsLink dataElements={props.dataElements}/>}
            </Container>
        </section>
    )
}

export default DiveDeeper;

