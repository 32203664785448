import React, { useEffect, useState } from 'react';
import useAppContext from '../utilities/use-app-context';
import { api } from '../utilities/fetch-json';
import message from '../common/message';
import '../style.css'
import SpinKitCube from '../common/SpinKitCube';
import WhiteListIP from './whiteListIP';
import localforage from 'localforage';
// import useGaTracker from '../common/useGaTracker'
import CheckThirdPartyCookie from '../utilities/CheckThirdPartyCookie';
import ApiToken from './apiToken';
import useHeapTracker from '../common/useHeapTracker'
import MainFooter from '../app-footer/MainFooter';
import { Button } from '@material-ui/core';
import CheckIcon from 'mdi-react/CheckIcon';

function DirectIntegration() {

  // useGaTracker();
  useHeapTracker();

  
  const { currentUser, config } = useAppContext();
  const { defaultConnectionId } = config || {};

  const [email, setEmail] = useState(
    currentUser ? currentUser.email : 'Unknown'
  );

  const [data, setData] = useState<any>([])
  const [filterData, setFilterData] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [selectedConn, setSelectedConn] = useState<any>('')
  const [showCopyMsg, setShowCopyMsg] = useState({
    host:false,
    username:false,
    database:false,
  })
  useEffect(() => {
    document.title = 'Direct Integration';
  }, [])
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCopyMsg({...showCopyMsg,host:false,username:false,database:false})
    },2000)

    return () => {
      clearTimeout(timer)
    }
  },[showCopyMsg])

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    if(currentUser.isActiveSolution){
      getConnections(signal)
    } else {
      setLoading(true)
    }
    return () => {
      abortController.abort();
    };
  }, [])


  useEffect(() => {
    connChange(selectedConn)
  }, [selectedConn])

  const connChange = async (val: any) => {
    let filterVal = data.filter((conn: any) => conn.id == val)
    setFilterData(filterVal[0])
    setSelectedConn(val)
  }

  const getConnections = async (signal:any) => {
    setLoading(true)
    const json = await api.get(`/api/getconnection/${currentUser.id}`, signal);
    if (json.error) {
      setLoading(false)

      return message.error(json.error);
    }
    if (json.data) {
      setData(json.data)
      let [selectedConnectionId] = await Promise.all([
        localforage.getItem('selectedConnectionId'),
      ]);
      if(json.data.find((data:any)=>data.id==selectedConnectionId)){
        setSelectedConn(selectedConnectionId) 
      }else{
        setSelectedConn(defaultConnectionId)
      }

      setLoading(false)
    }

  };

  const onCopyClick = (value:any,feild:String) => {
    if(feild === 'host'){
      navigator.clipboard.writeText(value?.data.host)
      setShowCopyMsg({...showCopyMsg, host:true})
    }else if(feild === 'username'){
      navigator.clipboard.writeText(value?.data.username)
      setShowCopyMsg({...showCopyMsg, username:true})
    }else if(feild === 'database'){
      navigator.clipboard.writeText(value?.data.database) 
      setShowCopyMsg({...showCopyMsg, database:true})
    }
}
const onCloseClick = (feild:any) => {
    if(feild === "host") {
      setShowCopyMsg({...showCopyMsg, host:false})
    } else if(feild === "username") {
      setShowCopyMsg({...showCopyMsg, username:false})
    } else if(feild === "database") {
      setShowCopyMsg({...showCopyMsg, database:false})
    } 
}

  return (
    <>

      <div

      >
        <CheckThirdPartyCookie />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="table-border">
                <div className="direct-heading"><span>Direct Integration</span></div>
                <p>Use these credentials to integrate directly to your ATTOM Cloud database.<br />
Visit our <a style={{ color: '#EB4734' }} href="https://cloud-help.attomdata.com/" target="_blank">online help center</a> for detailed instructions on direct integration.</p>
                {loading ? <div className="loading-div"><SpinKitCube /></div> : ''}
                <table className="table table-hover table-ui">

                  <tbody>
                    <tr>
                      <td className="border-top-none">Connection</td>
                      <td className="border-top-none">
                        <select style={{width:'70%'}}
                          value={selectedConn}
                          onChange={(val) => connChange(val.target.value)}
                          className="form-control hover">
                          {!selectedConn ? <option></option> : null}
                          {
                            data ? data.map((conn: any) => {
                              return (
                                <option key={conn.name} value={conn.id}>
                                  {conn.name}
                                </option>
                              )
                            })
                              : ''}
                        </select></td>
                    </tr>
                    <tr>
                      <td className="border-top-none">Driver</td>
                      <td>{(filterData && filterData.data) ? 'SQL Server' : ''}</td>
                    </tr>
                    <tr>
                      <td style={{width:'40%'}}>Host/Server/IP Address</td>
                      <td style={{width:'60%'}}>{filterData && filterData.data && filterData.data.host}
                      <Button  className="directIntegrationCopyButton solution-button" id="host" onClick={()  => onCopyClick(filterData,"host")}>Copy</Button>
                    {showCopyMsg.host ? <div className="copy-msg-direct">
                                        <div id="host" onClick={() => onCloseClick("host")} className="close-btn hover">x</div>
                                        <CheckIcon className="copy-tick" /> Host Copied to Clipboard
                                    </div> : null}
                                    </td>
                    </tr>
                    <tr>
                      <td>Port</td>
                      <td>{filterData && filterData.data && filterData.data.port}</td>
                    </tr>
                    <tr>
                      <td>Username</td>
                      <td>{filterData && filterData.data && filterData.data.username}
                      <Button  className="directIntegrationCopyButton solution-button" id="username" onClick={()  => onCopyClick(filterData,"username")}>Copy</Button>
                    {showCopyMsg.username ? <div className="copy-msg-direct">
                                        <div onClick={() => onCloseClick("username")} className="close-btn hover">x</div>
                                        <CheckIcon className="copy-tick" /> Username Copied to Clipboard
                                    </div> : null}
                                    </td>
                    </tr>
                    <tr>
                      <td>Password</td>
                      {/* <td>{filterData && filterData.data && filterData.data.password}</td> */}
                      <td>{(filterData && filterData.data) ? '(your account password)' : ''}</td>
                    </tr>
                    <tr>
                      <td>Authentication Type</td>
                      <td>{(filterData && filterData.data) ? 'SQL Login' : ''}</td>
                    </tr>
                    <tr>
                      <td>Database Name</td>
                      <td>{filterData && filterData.data && filterData.data.database}
                      <Button  className="directIntegrationCopyButton solution-button" id="database" onClick={()  => onCopyClick(filterData,"database")}>Copy</Button>
                    {showCopyMsg.database ? <div style={{marginLeft:'155px'}} className="copy-msg-direct">
                                        <div onClick={() => onCloseClick("database")} className="close-btn hover">x</div>
                                        <CheckIcon className="copy-tick" /> Database Copied to Clipboard
                                    </div> : null}
                      </td>
                    </tr>
                    <tr>
                      <td>SSL</td>
                      <td>{(filterData && filterData.data) ? 'True' : ''}</td>
                    </tr>
                    <tr>
                      <td>Read Only Application Intent</td>
                      <td>{(filterData && filterData.data) ? 'True' : ''}</td>
                    </tr>

                  </tbody>
                </table>
                <br/>
                <br/>
              <WhiteListIP />
              </div>
            </div>
          </div>
        </div>
        <ApiToken/>
      </div>
      <MainFooter/>
    </>
  );
}

export default React.memo(DirectIntegration);
