import React from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog';
import CloseIcon from 'mdi-react/CloseIcon';
import IconButton from '../common/IconButton';
import { useHistory } from 'react-router-dom';
import useAppContext from '../utilities/use-app-context';
import { api } from '../utilities/fetch-json';


const SolutionPackStatusPopup = () => {
const overlayRef = React.useRef(null);
const contentRef = React.useRef(null);
const {currentUser,config } = useAppContext();



  return (
    <DialogOverlay
    ref={overlayRef}
    style={{ background: 'rgb(0 0 0/ 60%)'}}
  >
    <DialogContent
          aria-label="Announcement"
          ref={contentRef}
          style={{
            boxShadow: '0px 10px 50px hsla(0, 0%, 0%, 0.33)',
            width: '60vw',
          }}
          className="purchaseDialog"
    >



<br/>
<p  className='p-6'>Your Account is expired. Please contact support for assistance, call at (800) 659-2877.</p>

    </DialogContent>
  </DialogOverlay> 
  )
}

export default SolutionPackStatusPopup;