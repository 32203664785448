import React, { useEffect, useState } from 'react';
import useAppContext from '../utilities/use-app-context';
import { api } from '../utilities/fetch-json';
import message from '../common/message';
import SpinKitCube from '../common/SpinKitCube';

function PropertyReportsLink() {
    const { currentUser,config } = useAppContext();
    const [token, setToken] = useState('');
    const [iframe,setIframe] = useState(false)


useEffect(() => {
    document.title = 'Property Navigator';
    getGdpToken()
},[])

    const INTERVAL_MS = 3540000;
    
    useEffect(() => {
        
        const interval = setInterval(() => {
        getGdpToken()
         
        }, INTERVAL_MS);
 
        return () => clearInterval(interval);
  
    }, [token,iframe])



    async function getGdpToken() {
        const json = await api.get('/api/pntoken');
        if (json.error) {
            return message.error(json.error);
        }
        if (json.data) {
            //   console.log(json.data)
            setToken(json.data)
        }
    }
    const handleIfrmeLoad = () => setIframe(true);
  
    return (
        <>
       {token && currentUser.isActiveSolution ? (<iframe src={`${config.propertyReportLink}id=${currentUser.id}&token=${token}`} style={{ height: '100vh', width: '100%' }} id='propertyreportsiframe' onLoad={handleIfrmeLoad}></iframe>) :<div className="loading-div"><SpinKitCube /></div>} 
       {!iframe && (<div className="loading-div"><SpinKitCube /></div>)}
       </>
    );
}

export default PropertyReportsLink;