import React from 'react';
import styles from './Logo.module.css';
import logo from '../images/attomlogo.png';
import { config } from 'process';
import useAppContext from '../utilities/use-app-context';
import { useHistory } from 'react-router-dom';
/**
 * A blue block that stretches beyond the padding in AppHeader
 * It could show a logo someday, or maybe be a primary button if it did some action
 */
function Logo() {
  const { config, currentUser } = useAppContext();
  const history = useHistory();
  if (!config){
    return null;
  }

  const handleClick = () => history.push('/home'); 
  // return <div className={styles.logo}><img src={logo} alt="logo" /></div>;
  return <div style={{cursor:'pointer'}} onClick={handleClick} className={styles.logo}  ><img src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/attomlogo.svg'} alt="logo" className="img-fluid" width="100"/></div>;
}

export default Logo;
