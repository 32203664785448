import React, { useEffect, useState } from 'react';
import useAppContext from '../utilities/use-app-context';
import { api } from '../utilities/fetch-json';
import message, { MessageDisplayer } from '../common/message';
import SpinKitCube from '../common/SpinKitCube';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import CloseIcon from 'mdi-react/CloseIcon';
import IconButton from '../common/IconButton';
import { search } from '@metrichor/jmespath';
import CheckThirdPartyCookie from '../utilities/CheckThirdPartyCookie'
import CheckIcon from 'mdi-react/CheckIcon';
// import useGaTracker from '../common/useGaTracker'
import useHeapTracker from '../common/useHeapTracker'
import MainFooter from '../app-footer/MainFooter';
import "./Profile.css"
import ChangePassword from './passwordChange';

function UserProfile() {

  // useGaTracker();
  useHeapTracker();
  const { currentUser,config } = useAppContext();
  const [invitaionLink, setInvitationLink] = useState('');
  const [email, setEmail] = useState(
    currentUser ? currentUser.email : 'Unknown'
  );
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState<any>('');
  const [company, setCompany] = useState<any>('');
  const [industry, setIndustry] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  const [showCopyMsg, setShowCopyMsg] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedStartPage, setSelectedStartPage] = useState('');
  const [passSuccess, setPassSuccess] = useState(false);
  const [userData, setUserData] = useState<any>('');
  const overlayRef = React.useRef(null);
  const contentRef = React.useRef(null);
  const close = () => {
    setChangePasswordDialog(false);
  };

  const openDialog = () => {
    setChangePasswordDialog(true);
  };

  function passwordCHange(){
    return window.location.href= config.sqlpadpasswordchangeurl + "&redirect_uri=" + encodeURI(config.publicUrl + "/profile");
   }
   useEffect(() => {
    document.title = 'Profile';
  }, [])
   useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    getProfile(signal);
    getInvitationLink(signal)
 
    return () => {
      abortController.abort();
    };
   },[])

   useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    getUserDetail(signal)
    return () => {
      abortController.abort();
    };
   },[])


   const handleStartingPage =  async() => {
    setLoading(true)
    const json = await api.put(`/api/users/${currentUser.id}`, {
      data: {
       starting_page: selectedStartPage,
      },
        action:"starting_page"
     });
 
     if (json.error) {
      setLoading(false)
        message.error('Changes updation failed');
        return;
     
     }
     if (json.data) {
      setLoading(false)
       message.success('Changes were saved successfully');
       return;
     }
   }

   const handlePrimaryChange = async () => {
    setLoading(true)
    const json = await api.put(`/api/users/${currentUser.id}`, {
      data: {
       primary_interest: selectedValue,
      },
      action:"primary_interest"
     });
 
     if (json.error) {
      setLoading(false)
        message.error('Changes updation failed');
        return;
     
     }
     if (json.data) {
      setLoading(false)
       message.success('Changes were saved successfully');
       return;
     }
  }
  const getUserDetail = async (signal: any) => {

    const json = await api.get(`/api/users/${currentUser.id}`, signal);
    if (json.error) {
      return message.error(json.error);
    }
    if (json.data) {
      setUserData(json.data)
    }else{
    
  
    }
  };
  useEffect(() => {
    if(userData.data  ){
     return setSelectedValue(userData.data.primary_interest)
    }
    if(userData.origin === "Property Reports"){
      setSelectedValue('Property Navigator')
    } else {
      setSelectedValue('Cloud Property Data')
    }
  },[userData])
  useEffect(() => {
       if(userData.data){
      return setSelectedStartPage(userData.data?.starting_page)
     } 
  },[userData])
  const getProfile = async (signal: any) => {
    setLoading(true);
    const json = await api.post('/api/getuser', { id: currentUser.userIdB2C }, signal);
    if (json.error) {
      setLoading(false);
      return message.error(json.error);
    }
    if (json.data) {
      setFirstName(json.data.givenName);
      setLastName(json.data.surname);
      setPhone(search(json.data,`extension_${config.b2cExtensionId}_phoneMobile`));
      setCompany(search(json.data,`extension_${config.b2cExtensionId}_company`));
      setIndustry(search(json.data,`extension_${config.b2cExtensionId}_industry`));
      setLoading(false);
    }else
    {
      setLoading(false);
      return message.success('Something went wrong');

    }
  };

  const updateProfile = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const body = {
      id: currentUser.userIdB2C,
      email,
      givenName: firstName,
      surname: lastName,
      mobilePhone: phone,
      companyName: company,
      industry,
    };
    const json = await api.post('/api/updateuser', body);
    if (json.error) {
      setLoading(false);
      return message.success('Profile updated successfully');
    }
    if (json.data) {
      setLoading(false);
      return message.success('Profile updated successfully');
    }
  };
  const onLinkClick = () => {
    setShowCopyMsg(true)
    navigator.clipboard.writeText(invitaionLink)
    setTimeout(() => {
     setShowCopyMsg(false);
    },3000)
}

const onCloseClick = () => {
  setShowCopyMsg(false)
}

const getInvitationLink = async (signal: any) => {

  const json = await api.get(`api/get-invitaion-link?email=${currentUser.email}`);
  if (json.error) {
    return message.error(json.error);
  }
  if (json.data) {
    setInvitationLink(json.data)
  }else{
  

  }
};


const filterChangeHandler = (e:any) => {
  setSelectedValue(e.target.value)
}

const startPageChangeHandler = (e:any) => {
  setSelectedStartPage(e.target.value)
}

  const closeDialog = () => {
    setChangePasswordDialog(false);
  };
  const passSuccessMsg = (state:boolean) => {
    setPassSuccess(state)
  };

  return (
    <>
    <div>
    <ChangePassword show={changePasswordDialog} onClose={closeDialog} passSuccessMsg={passSuccessMsg} />
      {loading ? <div className="loading-div"><SpinKitCube /></div> : ''}
    <div
        style={{
          minHeight: '90vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CheckThirdPartyCookie />
        <div className="container">
        <MessageDisplayer />
          <div className="row">
            <div className="mt-40 width-primary-interest" >
           
              <div className="card-body table-border">
              <div className="direct-heading"><span>Profile</span></div>
              <br />
                  <form>
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <label htmlFor="inputName">Name</label>
                        </div>
                        <div className="d-flex col-sm-9">
                          <div className="col-6 pl-0">
                            <input
                              type="text"
                              className="form-control w-100"
                              id="firstName"
                            readOnly
                            placeholder="First name"
                              value={firstName}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => setFirstName(e.target.value)}
                            />
                          </div>
                          <div className="col-6 pr-0">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="lastName"
                            placeholder="Last name"
                          readOnly
                            value={lastName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => setLastName(e.target.value)}
                          />
                        </div>
                        </div>
                      </div>
                    <div className="form-group row">
                      <div className="col-sm-3">
                          <label htmlFor="email">Email</label>
                        </div>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="email"
                            placeholder="Email"
                            readOnly
                            value={email}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setEmail(e.target.value)
                            }
                          />
                        </div>
                    </div>

                      <div className="form-group row">
                        <div className="col-sm-3">
                          <label htmlFor="phone">Phone</label>
                        </div>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="phone"
                            readOnly
                            placeholder="Phone"
                            value={phone?phone:''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setPhone(e.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-3">
                          <label htmlFor="Company">Company</label>
                        </div>
                        <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control w-100"
                          id="company"
                          readOnly
                          placeholder="Company"
                          value={company?company:''}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setCompany(e.target.value)
                          }
                        />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-3">
                         <label htmlFor="industry">Industry</label>
                        </div>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="industry"
                            readOnly
                            placeholder="Industry"
                            value={industry? industry:''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setIndustry(e.target.value)
                            }
                          />
                        </div>
                        </div>
                        
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <label htmlFor="password">Password</label>
                        </div>
                        <div className="col-sm-3">
                        <a className='password-link' onClick={openDialog}>
                          Change Password
                        </a>
                        </div>
                         { passSuccess && 
                         <div title='Click to close' onClick={() => passSuccessMsg(false)} className="col-sm-6 success-msg">
                        <CheckIcon className="copy-tick" /> Your password has been reset.
                        </div>
                        } 
                       
                      </div>
                        <div style={{'marginBottom': '5px'}} className="form-group row">
                           <div className="col-sm-3">
                             <label htmlFor="primary">Primary Interest</label>
                           </div>
                          <div className="col-sm-9">
                           <select className='primary-interest' 
                           value={selectedValue} onChange={filterChangeHandler}>
                            <option value="Cloud Property Data">ATTOM Cloud</option>
                            <option value="ATTOM API">ATTOM API</option>
                            <option  value="Property Navigator">ATTOM Property Navigator</option>
                           </select>
                           <button className="copy-btn" type="button" onClick={handlePrimaryChange}>Save</button>
                          </div>
                      </div>
                      <div style={{'marginBottom': '35px'}} className="form-group row">
                           <div className="col-sm-3">
                             <label htmlFor="startingpage">Starting Page</label>
                           </div>
                          <div className="col-sm-9">
                           <select className='primary-interest' 
                           value={selectedStartPage} onChange={startPageChangeHandler}>
                            <option value="HomePage">Home Page (default)</option>
                            <option value="PropertyNavigator">Property Navigator</option>
                            <option  value="SQLEditor">SQL Editor</option>
                           </select>
                           <button className="copy-btn" type="button" onClick={handleStartingPage}>Save</button>
                          </div>
                      </div>
                      { invitaionLink && invitaionLink.length>0 ? <div className="invite-team">
                      <div style={{'marginTop': '35px'}} className="form-group row invite-team-row">
                        <div className="col-sm-3">
                         <label style={{'width': 'max-content'}} htmlFor="industry">Invite Your Team</label>
                        </div>
                        <div className="col-sm-9">
                        <button className="copy-btn" type="button" onClick={onLinkClick}>Get Link</button>
                      
                        {showCopyMsg ? <div className="copy-msg-direct">
                                        <div onClick={onCloseClick} className="close-btn hover">x</div>
                                        <CheckIcon className="copy-tick" /> Invite Link Copied to Clipboard
                                    </div> : null}
                        </div>
                      </div>
                      </div>
                    :null}
                    {/* <div className="form-row">
                      <div className="form-group row col">
                        <label htmlFor="industry"></label>
                        <div className="col">
                        <input
                        hidden
                          type="button"
                          className="btn btn-primary"
                          value="Save"
                          onClick={updateProfile}
                        />
                        </div>
                      </div>
                    </div> */}
                  </form>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <MainFooter/>
    </>
  );
}

export default React.memo(UserProfile);