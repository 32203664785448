import React, { useState, useEffect } from 'react';
import './style.css';
import { Button,Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import useAppContext from './utilities/use-app-context';

type itemProps = {
    items?: any
}

const TrySolutionPack = (props: itemProps) => {
  const { config, currentUser } = useAppContext();
 
  const history = useHistory();


  const seeFullCataLogHandler = () => {
    history.push('/solutioncatalog');
  };

  const tryClicks = (solutionId: any) => {
    if (solutionId) {
      history.push(`/solutionpack/${solutionId}`);
    } else {
    }
  };
 

  return (
    <Col className='col-nO-sub' style={{ flexBasis: 'auto', marginTop: '20px' }}>
      <div>
        <h3 className="solution-heading ">Solution Packs to Try</h3>
        <div className="solution-more">
          {props.items && props.items.length > 0
            ? props.items.map((item:any,index:number) => {
                return (
                  <div key={index+1} className="solutionPack-column">
                    <div key={index+1} className="solutionPack-row-try">
                    <div style={{maxWidth: '50px'}}>
                      <img
                        className="solution-pack-img"
                        src={
                          item.subscription_icon
                            ? `data:image/svg+xml;base64,${item.subscription_icon}`
                            : process.env.PUBLIC_URL +
                              config.baseUrl +
                              '/assets/images/SP-Default.svg'
                        }
                      />
                      </div>
                      <p className="interest-licence interest-licence-try solution-center">
                        {item.subscription_name}
                      </p>
                      <p
                        className="button-tryClick"
                        onClick={() => tryClicks(item.subscription_code)}
                      >
                        Try
                      </p>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        <Button style={{minWidth: '200px'}} className="solution-button" onClick={seeFullCataLogHandler}>
          See the Full Catalog
        </Button>
      </div>
    </Col>
  );
};

export default TrySolutionPack;
