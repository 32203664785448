import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import Measure from 'react-measure';
import { Link } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/react-window-infinite-load... Remove this comment to see the full error message
import InfiniteLoader from 'react-window-infinite-loader';
import useSWR from 'swr';
import { useDebounce } from 'use-debounce';
import DeleteConfirmButton from '../common/DeleteConfirmButton';
import Drawer from '../common/Drawer';
import ErrorBlock from '../common/ErrorBlock';
import InfoBlock from '../common/InfoBlock';
import Input from '../common/Input';
import ListItem from '../common/ListItem';
import message from '../common/message';
import Select from '../common/Select';
import SpinKitCube from '../common/SpinKitCube';
import Text from '../common/Text';
import { api } from '../utilities/fetch-json';
import styles from './QueryList.module.css';
import QueryPreview from './QueryPreview';
import useAppContext from '../utilities/use-app-context';
// import useGaTracker from '../common/useGaTracker'
import useHeapTracker from '../common/useHeapTracker'


const SHARED = 'SHARED';
const MY_QUERIES = 'MY_QUERIES';
const ALL = 'ALL';
const PRELOAD_QUERIES = 'PRELOAD_QUERIES';
const SOLUTION_QUERIES = 'SOLUTION_QUERIES';

type Props = {
  visible?: boolean;
  preload?:boolean;
  onClose?: (...args: any[]) => any;
};

function QueryListDrawer({ onClose, visible,preload }: Props) {

  // useGaTracker();
  useHeapTracker();

  
  const [preview, setPreview] = useState(null);
  const [search, setSearch] = useState('');
  const [creatorSearch, setCreatorSearch] = useState(MY_QUERIES);
  const [selectedVal, setSelectedVal] = useState(MY_QUERIES);
  const [sort, setSort] = useState('SAVE_DATE');
  const [connectionId, setConnectionId] = useState('');
  const [dimensions, setDimensions] = useState({
    width: -1,
    height: -1,
  });
  const [debouncedSearch] = useDebounce(search, 400);

  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(null);
  const [error, setError] = useState(null);

  let allConn: any = [];
  const [allCon, setAllCon] = useState([]);
  const { currentUser } = useAppContext();
  const [subscription, setSubscription] = useState([])
  const [subscriptionCode, setSubscriptionCode] = useState()

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    getSubscription(signal)
    return () => {
      abortController.abort();
    };
  }, []);

  const getSubscription = async (signal: any) => {
    const json = await api.get(`/api/get-default-solution`, signal);
    if (json.data) {
      var result = json.data.reduce((unique:any, o:any) => {
        if(!unique.some((obj:any) => obj.subscription_code=== o.subscription_code)) {
          unique.push(o);
        }
        return unique;
    },[]);
      setSubscription(result.sort((a:any, b:any) => a.subscription_name.localeCompare(b.subscription_name)))
      const  data : any = localStorage.getItem('subscriptionCode') ? localStorage.getItem('subscriptionCode') : null
      if(data) {
        setSelectedVal(JSON.parse(data))
        setCreatorSearch(SOLUTION_QUERIES)
        setSubscriptionCode(JSON.parse(data))
      }
    }
    if (json.error) {
      // return message.error(json.error);
    }

  }

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const connAccess = api.get(`/api/connection-accesses?id=${currentUser.id}`, signal);
    connAccess.then((res) => {
      // console.log("Conn Access",res.data)
      // console.log("Conn",connections)
        res.data.forEach((element: any) => {
        let data = connections.filter(
          (item: any) => item.id == element.connectionId && element.userEmail==currentUser.email
        );
        allConn.push(...data);
      });
      setAllCon(allConn);
      // console.log(allConn);
      return () => {
        abortController.abort();
      };
    });
  }, []);

useEffect(() => {
  if (preload) {
    setCreatorSearch(PRELOAD_QUERIES);
  }
}, [preload]);

const getSolutionPackValue = (value:any,e:any) => {
  const selectedIndex = e.target.options.selectedIndex;
  // console.log(e.target.options[selectedIndex].getAttribute('data-key'));
  setSubscriptionCode(value)
  if(value=='MY_QUERIES'){
    setCreatorSearch(MY_QUERIES)
    setSelectedVal(MY_QUERIES)
  }else{
    setCreatorSearch(SOLUTION_QUERIES)
    setSelectedVal(value)

  }
};

  let params = {
    limit: 20,
  };

  if (sort === 'SAVE_DATE') {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'sortBy' does not exist on type '{ limit:... Remove this comment to see the full error message
    params.sortBy = '-updatedAt';
  } else {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'sortBy' does not exist on type '{ limit:... Remove this comment to see the full error message
    params.sortBy = '+name';
  }

  if (creatorSearch === MY_QUERIES) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'ownedByUser' does not exist on type '{ l... Remove this comment to see the full error message
    params.ownedByUser = true;
  } else if (creatorSearch === SHARED) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'ownedByUser' does not exist on type '{ l... Remove this comment to see the full error message
    params.ownedByUser = false;
  } else if (creatorSearch=== SOLUTION_QUERIES) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'solutionPack' does not exist on type '{ l... Remove this comment to see the full error message
    params.solutionpack = true;
   // @ts-expect-error ts-migrate(2339) FIXME: Property 'subscriptionCode' does not exist on type '{ l... Remove this comment to see the full error message
    params.subscriptionCode = subscriptionCode;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'ownedByUser' does not exist on type '{ l... Remove this comment to see the full error message
    params.ownedByUser = false;
    params.limit = 200;
  }

  if (debouncedSearch) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'search' does not exist on type '{ limit:... Remove this comment to see the full error message
    params.search = debouncedSearch;
  }

  if (connectionId) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'connectionId' does not exist on type '{ ... Remove this comment to see the full error message
    params.connectionId = connectionId;
  }

  const initialUrl =
    '/api/queries?' + queryString.stringify(params, { arrayFormat: 'bracket' });

  const getQueries = useCallback(
    (url) => {
      setLoading(true);
      // This cannot use SWR at this time
      // as we need to use links and manage state
      api.get(url).then((response) => {
        const { data, links, error } = response;
        setLoading(false);
        setError(error);
        if (links && links.next) {
          setNext(links.next.url);
        } else {
          setNext(null);
        }
        if (url === initialUrl) {
          setQueries(data);
        } else {
          setQueries((queries) => queries.concat(data));
        }
      });
    },
    [initialUrl]
  );

  // (re)fetch queries when visible
  // New queries may have been added since last viewing
  useEffect(() => {
    if (visible) {
      getQueries(initialUrl);
    }
  }, [visible, initialUrl, getQueries]);

  let { data: tagData } = useSWR('/api/tags');
  const tags = tagData || [];

  let { data: connectionsData } = useSWR('/api/connections');
  const connections = connectionsData || [];

  const deleteQuery = async (queryId: any) => {
    const { error } = await api.delete(`/api/queries/${queryId}`);
    if (error) {
      return message.error(error);
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
    setQueries((queries) => queries.filter((q) => q.id !== queryId));
    setPreview(null);
  };

  function handleClose() {
    setPreview(null);
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onClose();
  }

  const Row = ({ index, style }: any) => {
    const query = queries[index];
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'preLoad' does not exist on type 'never'.
    const solutionPackURL = `/queries/${query.id}` + '?solutionpack=true';
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
    const queryUrl = query.solutionpack ? solutionPackURL : `/queries/${query.id}`;

    // `/queries/${query.id}&preLoad=${isPreLoad}`
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'chart' does not exist on type 'never'.
    const hasChart = query && query.chart && query.chart.chartType;

    return (
      <ListItem
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
        key={query.id}
        className={styles.ListItem}
        onMouseEnter={() => setPreview(query)}
        onMouseLeave={() => setPreview(null)}
        style={style}
      >
        <Link className={styles.queryLink} to={queryUrl} onClick={handleClose}>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'never'. */}
          {query.name}
          <br />
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'connection' does not exist on type 'neve... Remove this comment to see the full error message
          <Text type="secondary">{query.connection.name}</Text> */} 
        </Link>
        <div className={styles.listItemActions}>
          <div style={{ width: 8 }} />
          <div style={{ width: 4 }} />
          <DeleteConfirmButton
            icon
            key="del"
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'never'.
            confirmMessage={`Delete ${query.name}`}
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
            onConfirm={(e: any) => deleteQuery(query.id)}
            //// @ts-expect-error ts-migrate(2339) FIXME: Property 'canDelete' does not exist on type 'never... Remove this comment to see the full error message
            //disabled={!query.canDelete}
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'canDelete' does not exist on type 'never... Remove this comment to see the full error message
            hidden={!query.canDelete}
          >
            Delete
          </DeleteConfirmButton>
        </div>
      </ListItem>
    );
  };

  const loadMore = () => {
    if (!loading && next) {
      getQueries(next);
    }
  };

  return (
    <Drawer
      title={'Queries'}
      visible={visible}
      width="600px"
      onClose={handleClose}
      placement="left"
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div className={styles.filterContainer}>
          <div className={styles.filterRow}>
            <Select
              style={{ width: '250px', marginRight: 8, flex: '0 0 auto' }}
              value={selectedVal}
              onChange={(e: any) => getSolutionPackValue(e.target.value,e)}
              // defaultValue={}
            >
              <option value={MY_QUERIES}>My queries</option>
              {subscription.map((subs: any) => {
                return (
                  <option key={subs.subscription_code} value={subs.subscription_code}>
                    {subs.subscription_name}
                  </option>
                );
              })}
            </Select>

            {creatorSearch === MY_QUERIES && (
                <>
            <Select
              style={{ marginRight: 8 }}
              value={connectionId}
              onChange={(e: any) => setConnectionId(e.target.value)}
              hidden
            >
              <option value="">All connections</option>
              {allCon.map((connection: any) => {
                return (
                  <option key={connection.id} value={connection.id}>
                    {connection.name}
                  </option>
                );
              })}
            </Select>

            <Select
              style={{ width: 170, flex: '0 0 auto' }}
              value={sort}
              onChange={(e: any) => setSort(e.target.value)}
            >
              <option value="SAVE_DATE">Order by last saved</option>
              <option value="NAME">Order by name</option>
            </Select>
                </>
              )}  

          </div>
          <div className={styles.filterRow}>
            <div style={{ flex: '1 1 auto', width: '50%' }}>
              {creatorSearch === MY_QUERIES && (
                <Input
                  style={{ height: 36, width: 220 }}
                  placeholder="Search"
                  value={search}
                  onChange={(e: any) => setSearch(e.target.value)}

                />
              )}              
            </div>
          </div>
        </div>

        <Measure
          bounds
          onResize={(contentRect) => {
            // @ts-expect-error ts-migrate(2345) FIXME: Type 'undefined' is not assignable to type 'SetSta... Remove this comment to see the full error message
            setDimensions(contentRect.bounds);
          }}
        >
          {({ measureRef }) => (
            <div
              ref={measureRef}
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
              }}
            >
              {loading && queries.length === 0 && (
                <div className="h-100 w-100 flex-center">
                  <SpinKitCube />
                </div>
              )}
              {error && <ErrorBlock>Error getting queries</ErrorBlock>}
              {!error && !loading && queries.length === 0 && (
                <div style={{ height: 150, width: '100%' }}>
                  <InfoBlock>No queries found</InfoBlock>
                </div>
              )}
              {!error && queries.length > 0 && (
                <InfiniteLoader
                  isItemLoaded={(index: any) => index < queries.length}
                  itemCount={1000}
                  loadMoreItems={loadMore}
                >
                  {({ onItemsRendered, ref }: any) => (
                    <List
                      // position absolute takes list out of flow,
                      // preventing some weird react-measure behavior in Firefox
                      style={{ position: 'absolute' }}
                      ref={ref}
                      onItemsRendered={onItemsRendered}
                      height={dimensions.height}
                      itemCount={queries.length}
                      itemSize={60}
                      width={dimensions.width}
                      overscanCount={2}
                    >
                      {Row}
                    </List>
                  )}
                </InfiniteLoader>
              )}
            </div>
          )}
        </Measure>

        {/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
        <QueryPreview key={preview && preview.id} query={preview} />
      </div>
    </Drawer>
  );
}

export default React.memo(QueryListDrawer);
