import React from 'react'
import { useHistory } from 'react-router-dom';

type PurchaseProps = {
  isPurchased: boolean, 
          }
declare global {
  interface Window {
    Beacon: any;
  }
}
const FreeTrial = (props:PurchaseProps) => {
  const history = useHistory()

  const dataGuideClick = () => {
    return window.open(
      'https://cloud-help.attomdata.com/collection/332-data-guide',
      '_blank'
    );
}

const chatClick = () => {
  window.Beacon('open');
  window.Beacon('navigate', '/ask/message');
};

  return (
    <div>
       {!props.isPurchased ? <div className="trial-main"> <p className="trial-heading">Have questions? Need help?</p>
        <p className="trial-text">Try our <span className="trial-link"  onClick={dataGuideClick}>Data Guide</span>,
         or contact Tech Support by <span className="trial-link"><a className='trial-anchor' href="mailto:attom.cloud@attomdata.com">email</a></span> or <span onClick={chatClick} className="trial-link">chat.</span></p></div>

    : <div className="trail-main-np"><h3 className="trial-heading-np">Your Free Trial includes 30 days access to this Solution Pack.</h3>
     <p className="trial-text-np">To extend your usage, contact Sales at (800) 659-2877</p> </div>}
      </div>
  )
}

export default FreeTrial;

