import Button from '../common/Button';
import React, { useState } from 'react';
import styles from '../common/Button.module.css';
import { useHistory } from 'react-router-dom';
import '../style.css'

function NewQueryButton(props: any) {
    const history = useHistory();
    const handleClick = () => history.push('/queries');
    const isActive=props["isActive"]==true;  
  return (
    <>
      <Button className={styles.subheaderbutton + ' menu '+ ` ${isActive? styles.active + ' sub-menu-active': ''}`} onClick={handleClick}>
        New Query
      </Button>
    </>
  );
}

export default NewQueryButton;
