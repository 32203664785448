import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Authenticated from './Authenticated';
import ForgotPassword from './ForgotPassword';
import PasswordReset from './PasswordReset';
import PasswordResetRequested from './PasswordResetRequested';
import QueryChartOnly from './QueryChartOnly';
import QueryEditor from './queryEditor/QueryEditor';
import QueryTableOnly from './QueryTableOnly';
import useAppContext from './utilities/use-app-context';
import Visualize from './visualize/visualize';
import HandleAuthAAD from './HandleAuthAAD'
import UserProfile from './users/profile'
import DirectIntegration from './users/directIntegration'
import TerrmOfUse from './documentation/termsOfUse'
import PropertyReports from './propertyReports/Propertyreports';
import HomePage from './HomePage'
import SolutionCatlog from './solutionCatlog/SolutionCatlog';
import SolutionPackHome from './solutionPack/SolutionPackHome';
import HandleRedirect from './HandleRedirect';
import {reactPlugin } from './common/ApplicationInsightsService';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import RedirectPropertyNavigator from './propertyReports/RedirectPropertyNavigator';
const queryString = require('query-string');

function Routes() {
  const { config, currentUser } = useAppContext();

  if (!config) {
    return null;
  }
  
 
const appInsights = new ApplicationInsights({
  config: {
    connectionString: config.appInsightsConnection,
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true
  }
}); 
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env:any) => {
 const page =    env.ext.trace.name.split('/')[1].toString()
    env.tags = env.tags || [];
    env.tags["ai.cloud.role"] = page
    ;
});

  function redirectToNew() {
    if (currentUser) {
      return <Redirect to={'/home'} />;
    }
    return <Redirect to={'/login'} />;
  }

  function redirectToLogin() {
    localStorage.removeItem('showDialog');
    let state = '';
    let header = '';
    if (queryString.parse(window.location.search).state) {
      state = '&state=' + queryString.parse(window.location.search).state;
    }
    if (queryString.parse(window.location.search).h=='true') {
      header='?h=true'
    }

    window.location.href =
      config.sqlpadoidcsigninurl +
      '&redirect_uri=' +
      encodeURI(config.publicUrl + '/auth/oidc/callback' + state + header);
    return null;
  }


  function redirectToQuery() {
    if (currentUser) {
      return <Redirect to={'/queries/new'} />;
    }
    return <Redirect to={'/login'} />;
  }
  return (
    <Router basename={config.baseUrl}>
      <Switch>
        <Route exact path="/" render={redirectToNew} />
        <Route exact path="/login" render={redirectToLogin} />
        <Route exact path="/queries" render={redirectToQuery} />

    
        <Route
          exact
          path="/queries/:queryId"
          render={({ match }) => (
            <Authenticated>
              <QueryEditor queryId={match.params.queryId} />
            </Authenticated>
          )}
        />
        <Route
          exact
          path="/query-table/:queryId"
          render={({ match }) => (
            <QueryTableOnly queryId={match.params.queryId} />
          )}
        />
        <Route
          exact
          path="/query-chart/:queryId"
          render={({ match }) => (
            <QueryChartOnly queryId={match.params.queryId} />
          )}
        />
        <Route exact path="/signin" render={redirectToLogin} />
        {/* <Route exact path="/signup" render={() => <SignUpIdentity />} />   */}
        {/* <Route exact path="/signin" render={() => <SignUp />} />   */}
        <Route
          exact
          path="/forgot-password"
          render={() => <ForgotPassword />}
        />
        <Route
          exact
          path="/password-reset/:passwordResetId"
          render={({ match }) => (
            <PasswordReset passwordResetId={match.params.passwordResetId} />
          )}
        />
           <Route path="/auth" render={(props) => <HandleAuthAAD {...props} />}/>
        <Route
          exact
          path="/password-reset"
          render={() => <PasswordResetRequested />}
        />
        {/* <Route exact path="/usagemetrics" render={() => ( <Authenticated><UsageMetrics /></Authenticated>)} /> */}
        <Route exact path="/reports" render={() => (<Authenticated><Visualize /></Authenticated>)} />
        <Route exact path="/propertyreports" render={() => (<Authenticated><RedirectPropertyNavigator /></Authenticated>)} />
        <Route exact path="/propertynavigator" render={() => (<Authenticated><RedirectPropertyNavigator /></Authenticated>)} />
        <Route exact path="/pn" render={() => (<Authenticated><PropertyReports /></Authenticated>)} />
        <Route exact path="/solutioncatalog/:favroritesId?" render={({ match }) => (<Authenticated><SolutionCatlog favroritesId={match.params.favroritesId} /></Authenticated>)} />
        <Route
          exact
          path="/solutionpack/:solutionId"
          render={({ match }) => (
            <Authenticated><SolutionPackHome solutionId={match.params.solutionId.toLowerCase()} /></Authenticated>
          )}
        />
        <Route exact path="/profile" render={() => (<Authenticated><UserProfile /></Authenticated>)} />
        <Route exact path="/directintegration" render={() => (<Authenticated><DirectIntegration /></Authenticated>)} />
        <Route exact path="/termsofuse" render={() => (<TerrmOfUse />)} />
        <Route exact path="/home" render={() => (<Authenticated><HomePage /></Authenticated>)} />
        <Route exact path="/handleredirect" render={() => (<Authenticated><HandleRedirect /></Authenticated>)} />
        <Route render={redirectToLogin} />
      </Switch>
    </Router>
  );
}

export default Routes;
