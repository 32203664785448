import React from 'react'
import useAppContext from '../utilities/use-app-context';
import Modal from 'react-bootstrap/Modal';

type modalProps = {
show:Boolean,
handleClose:() => void,
subscriptionVideo:String
}
 
const SolutionVedioModel = (props:modalProps) => {
  const { config, currentUser } = useAppContext();
  return (
    <> 
      <Modal
        className="modalVideo"
        show={props.show}
        onHide={props.handleClose}
      >
        <div className='handleCloseVideo'>
          <img
          onClick={props.handleClose}
            className="solutionTicked closeButton"
            src={
              process.env.PUBLIC_URL +
              config.baseUrl +
              '/assets/images/closemodel.svg'
            }
            alt="Image not found"
          />
            <video className="video-height" controls autoPlay>
            <source src= {`${config.publicUrl}/api/downloads?type=${'videos'}&file=${props.subscriptionVideo}`} type="video/mp4" />
                </video>
   </div>
      </Modal>
      
    </>
  );
}

export default SolutionVedioModel