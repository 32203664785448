import React, { useEffect, useState } from 'react';
import { api } from './utilities/fetch-json';
import { Redirect } from 'react-router-dom';

function HandleAuthAAD(props:any) {

  const [code,setCode]=useState('')
const[message,setMessage]=useState('Getting Code')
  useEffect(() => {
    document.title = 'Auth';
    let code= props.location.search.substr(6, props.location.search.length-1)
    setCode(code)
    setMessage('Got code')
    getToken(code)
  }, []);

  async function getToken(data:any) {
    const json = await api.post(`/api/token`,{token:data});
    setMessage('Getting Access token')

    if (json.data) {
     console.log(json)
    setMessage('Got access token')
    // setCode(json.data.id_token)

    } else {
    setMessage('Something went wrong')
     
    }
  }
  
  return (
    <div style={{ width: 'auto', textAlign: 'center', margin: '100px auto' }}>
    <h4 style={{ width: 'auto'}}>{message}</h4><br></br> {code} 
    </div>
  );
}

export default HandleAuthAAD;
