import React,{useState} from 'react';
import NewQueryButton from './NewQueryButton';
import HistoryButton from './HistoryButton';
import ForeClosureButton from './ForeClosure';
import QueryListButton from './QueryListButton';
import ToolbarNewQueryButton from './ToolbarNewQueryButton';
import AppHeaderSpacer from './AppHeaderSpacer';
import { MessageDisplayer } from '../common/message';
import AppHeaderAdminSection from './AppHeaderAdminSection';
import useAppContext from '../utilities/use-app-context';
import WelcomeDialog from '../common/WelcomePopUp'


// returning the submenus for cloud data.
function Appheader() {
  const { currentUser, config } = useAppContext();
  const [showDialog, setShowDialog] = useState(currentUser.newUser);
  const [showQuery, setShowQuery] = useState(false);

  let isNewQueryActive = false, isUsageActive = false, isDocumentationActive=false, showSubHeader=true
  const url = window.location.href;
  if (url.includes("/queries/new")) {    
    isNewQueryActive = true;
    isUsageActive = false;
    isDocumentationActive=false;
  }
  else if (url.includes("/usagemetrics")) {
    isNewQueryActive = false;
    isUsageActive = true;
    isDocumentationActive=false;
  }
  else if (url.includes("documentation")) {
    isNewQueryActive = false;
    isUsageActive = false;
    isDocumentationActive=true;
  } else if (url.includes("visualize")) {
   showSubHeader=false
  }

  function preLoadcb()
{
  setShowQuery(true)
}

  return (
    <>
           {/* <WelcomeDialog show={showDialog} cb={preLoadcb}/> */}
      <div
        style={{
          width: '100%',
          color: '#fff',
          backgroundColor: '#4f4f4f',
          padding: 12,
          borderBottom: '1px solid rgb(204, 204, 204)',
        }}
      >
        <div style={{ display: 'flex' }}>
          { showSubHeader? <NewQueryButton isActive={isNewQueryActive}/>:null}
         { showSubHeader?  <QueryListButton openQuery={showQuery} />:null}
         {/*Below commented line is for DD-126 */}
          {/* { showSubHeader? <ToolbarNewQueryButton isActive={isUsageActive} />:null}  */}
          { showSubHeader? null:<ForeClosureButton isActive={true}/>}
          <AppHeaderSpacer grow />
        <MessageDisplayer />
        {/* <AppHeaderAdminSection /> */}
        </div>
      </div>
      </>
  );
}

export default React.memo(Appheader);
