import Button from '../common/Button';
import React from 'react';
import styles from '../common/Button.module.css';
import { useHistory } from 'react-router-dom';
import '../style.css'

function MainHeaderCloud(props: any) {
  const history = useHistory();
  const handleClick = () => history.push('/queries/new');   
  const isActive=props["isActive"]==true;  
  return (
    <>
      <Button className={styles.headerbutton + ' menu'+ ` ${isActive? styles.active +' menu-active': '' + 'bg-transparent text-sm-dark text-start'}`} onClick={handleClick}>
        <b className={`${styles.headername} ml-n4`}>SQL Editor</b>
      </Button>
    </>
  );
}

export default React.memo(MainHeaderCloud);
