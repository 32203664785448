import Button from '../common/Button';
import React from 'react';
import styles from '../common/Button.module.css';
import { useHistory } from 'react-router-dom';
import '../style.css'

function APIExplorerButton(props: any) {
  const history = useHistory();
  function redirectUrl()
  {
    return window.open(
      'https://api.developer.attomdata.com/docs',
      '_blank' 
    );
  }   
  const isActive=props["isActive"]==true;  
  return (
    <>
      <Button style={{minWidth:0}} className={styles.headerbutton + ' menu'+ ` ${isActive? styles.active +' menu-active': '' + 'bg-transparent text-sm-dark text-start '}`} onClick={redirectUrl}>
        <b>API Explorer</b>
      </Button>
    </>
  );
}

export default React.memo(APIExplorerButton);
