import SolutionPacks from './SolutionPacks';
import message from './common/message';
import SpinKitCube from './common/SpinKitCube'
import React, { useEffect } from 'react';
import Spacer from './common/Spacer';
import Mainheader from './app-header/MainHeader';
import useAppContext from './utilities/use-app-context';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card, Button } from 'react-bootstrap';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import CloseIcon from 'mdi-react/CloseIcon';
import IconButton from './common/IconButton';
import './style.css';
import { api } from './utilities/fetch-json';
// import useGaTracker from './common/useGaTracker';
import useHeapTracker from './common/useHeapTracker';
import MainFooter from './app-footer/MainFooter';
import PropertyApi from './PropertyApi';

function HomePage() {
  const { config, currentUser } = useAppContext();
  const history = useHistory();
  const [isIE, setIsIE] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [userData, setUserData] = React.useState<any>('');
  const [loading, setLoading] = React.useState(false);
  const overlayRef = React.useRef(null);
  const contentRef = React.useRef(null);

  useHeapTracker();
  // useGaTracker();

  useEffect(() => {
    document.title = 'ATTOM Cloud';
    var ua = window.navigator.userAgent;
    var isIE = /MSIE|Trident/.test(ua);
  if (isIE) {
    //IE specific code goes here
    setIsIE(true);
  } else {
    setIsIE(false);
  }
  updateUserFlag();
  getUserDetail();
  }, []);

  const updateUserFlag = async () => {
    const json = api.put(`/api/users/${currentUser.id}`, { newUser: false });
  };

  const solutionCatlogClick = () => {
    history.push('/solutioncatalog');
  };
  const directClick = () => {
    history.push('/directintegration');
  };
  const propertyClick = () => {
    if (isIE) {
      return window.open(
        'https://cloud-help.attomdata.com/article/441-browsers',
        '_blank'
      );
    } else {
      history.push('/propertyreports');
    }
  };
  const getUserDetail = async () => {

    const json = await api.get(`/api/users/${currentUser.id}`);

    if (json.error) {
      setLoading(true);
      return message.error(json.error);
    }
    if (json.data) {
      setLoading(true);
      setUserData(json.data)
    }else{
    
  
    }
  };
  const editorClick = () => {
    history.push('/queries/new');
  };
  const apiClick = () => {
    return window.open('https://api.developer.attomdata.com/docs', '_blank');
  };
  const newClick = () => {
    return window.open(
      'https://cloud-help.attomdata.com/category/449-whats-new',
      '_blank'
    );
  };
  const introClick = () => {
    return window.open('https://youtu.be/pzuuWB9phr8', '_blank');
  };
  const userGuideClick = () => {
    return window.open(
      'https://cloud-help.attomdata.com/collection/329-user-guide',
      '_blank'
    );
  };
  const dataGuideClick = () => {
    return window.open(
      'https://cloud-help.attomdata.com/collection/332-data-guide',
      '_blank'
    );
  };
  const datacatlogClick = () => {
    return window.open(
      'https://cloud-help.attomdata.com/article/371-data-catalog',
      '_blank'
    );
  };

  const webinarClick = () => {
    return window.open(
      'https://www.attomdata.com/solutions/attom-cloud/attom-cloud-webinar/',
      '_blank'
    );
  };
  const closeAction =() => {
    setShowDialog(false);
}

const openAction =() => {
  setShowDialog(true);

}
const helpTopic = () => {
  window.open('https://cloud-help.attomdata.com/')
}

  const componentOrder = () => {

    if (userData.data) {
      if (userData.data.primary_interest === "Property Navigator") {
        return (<>
          <PropertyApi userData={userData}/>
          <SolutionPacks />
        </>
        )
      }
      if (userData.data.primary_interest === "Cloud Property Data") {
        return (<>
          <SolutionPacks />
          <PropertyApi userData={userData}/>
        </>
        )
      }
      if (userData.data && userData.data.primary_interest === "ATTOM API") {
        return (<>
          <PropertyApi userData={userData}/>
          <SolutionPacks />
        </>
        )
      }

    } else {
      if (currentUser.origin && currentUser.origin == 'Property Reports') {
        return (<>
          <PropertyApi userData={userData}/>
          <SolutionPacks />
        </>
        )
      }
      if (currentUser.origin && currentUser.origin == 'Cloud Property Data') {
        return (<>
          <SolutionPacks />
          <PropertyApi userData={userData}/>
        </>
        )
      }
      if (currentUser.origin && currentUser.origin == 'ATTOM API') {
        return (<>
          <PropertyApi userData={userData}/>
          <SolutionPacks />
        </>
        )
      }
    }

  // no data and no origin
  if (userData && userData.data === null || userData.data === ''   || currentUser.origin == null ||  currentUser.origin == ''){
    return (
      <>
       <SolutionPacks />
        <PropertyApi userData={userData}/>
      </>
    );
  }
  
};

  const showMessage = () => {
    if (isIE) {
      return (
        <div className="card-img-overlay">
          <div className="info-msg">
            <p>
              We are unable to show this content due to your browser's
              configuration
            </p>
            <p>
              Please click <u>here</u> for more details
            </p>
          </div>
        </div>
      );
    }
  };

  const grayscale = () => {
    if (isIE) {
      return 'grayscale-hover';
    }
  };

  return (
    <>
      <Mainheader isIE={isIE} />
      <div>
        <iframe
          src="https://wu2attomaadb2c.blob.core.windows.net/root/start.html"
          style={{ display: 'none' }}
        />
      </div>
      <div style={{ width: '90%', margin: 'auto' }}>
  {showDialog &&    <DialogOverlay
        ref={overlayRef}
        style={{ background: 'rgb(0 0 0/ 60%)' }}
        isOpen={showDialog}
      // onDismiss={close}
      >
        <DialogContent
          aria-label="Announcement"
          ref={contentRef}
          style={{
            boxShadow: '0px 10px 50px hsla(0, 0%, 0%, 0.33)',
            width: '60vw',
          }}
          className="purchaseDialog"
        >
       <IconButton  onClick={closeAction} className="btn-close">
            <CloseIcon size={'48px'} />
          </IconButton>

<br/>
<p className='p-6'>For pricing or to purchase a license to this Solution Pack, Call Sales at (800) 659-2877.</p>
  
        </DialogContent>
      </DialogOverlay> }
        <Container fluid>
          <Spacer size={4} />
          <Row>
            <Col xs={12} md={12} lg={12} xl={12}>
              <div className="welcome-msg">
                Welcome to ATTOM Cloud, {currentUser.displayName}
                <Button style={{minWidth: '150px'}} className="solution-button button-expand"onClick={openAction}>Contact Sales</Button>
              </div>
            </Col>
          </Row>
          <Spacer size={4} />
         {!loading && <SpinKitCube />} 
        {loading && componentOrder()}
          <Spacer size={4} />
          <Row>
            <Col xs={12} md={12} className="learn-more-help">
              <div className="row-heading">Learn</div>
              <span className="click-solution" onClick={helpTopic}>See all help topics</span>
            </Col>
          </Row>
          <Row>
            <Col className="col-*">
              <Card id="WhatsNew" className="card-style" onClick={newClick}>
                <Card.Img
                  className="card-image"
                  variant="bottom"
                  src={
                    process.env.PUBLIC_URL +
                    config.baseUrl +
                    '/assets/images//home-page/new.svg'
                  }
                />
                <Card.Body>
                  <Card.Title className="card-title">What’s New</Card.Title>
                  <Card.Text className="card-text">
                    New data and features are being added to ATTOM Cloud every
                    week. Find out about the latest improvements made and our
                    plans for the future.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col className="col-*">
              <Card id="IntroVideo" className="card-style" onClick={introClick}>
                <Card.Img
                  className="card-image"
                  variant="bottom"
                  src={
                    process.env.PUBLIC_URL +
                    config.baseUrl +
                    '/assets/images//home-page/icon-video.svg'
                  }
                />
                <Card.Body>
                  <Card.Title className="card-title">Intro Video</Card.Title>
                  <Card.Text className="card-text">
                    Take a quick video tour of ATTOM Cloud.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-*">
              <Card
                id="UserGuide"
                className="card-style"
                onClick={userGuideClick}
              >
                <Card.Img
                  className="card-image"
                  variant="bottom"
                  src={
                    process.env.PUBLIC_URL +
                    config.baseUrl +
                    '/assets/images//home-page/icon-user-guide.svg'
                  }
                />
                <Card.Body>
                  <Card.Title className="card-title">User Guide</Card.Title>
                  <Card.Text className="card-text">
                    Get detailed info on how to use ATTOM Cloud.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-*">
              <Card
                id="DataGuide"
                className="card-style"
                onClick={dataGuideClick}
              >
                <Card.Img
                  className="card-image"
                  variant="bottom"
                  src={
                    process.env.PUBLIC_URL +
                    config.baseUrl +
                    '/assets/images//home-page/icon-data-guide.svg'
                  }
                />
                <Card.Body>
                  <Card.Title className="card-title">Data Guide</Card.Title>
                  <Card.Text className="card-text">
                    Learn about our data categories and data elements, and how
                    to use it.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-*">
              <Card
                id="DataCatalog"
                className="card-style"
                onClick={datacatlogClick}
              >
                <Card.Img
                  className="card-image"
                  variant="bottom"
                  src={
                    process.env.PUBLIC_URL +
                    config.baseUrl +
                    '/assets/images/home-page/icon-data-catalog.svg'
                  }
                />
                <Card.Body>
                  <Card.Title className="card-title">Data Catalog</Card.Title>
                  <Card.Text className="card-text">
                    A detailed index to all the data we offer.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col className="col-*">
              <Card id="Webinars" className="card-style" onClick={webinarClick}>
                <Card.Img
                  className="card-image"
                  variant="bottom"
                  src={
                    process.env.PUBLIC_URL +
                    config.baseUrl +
                    '/assets/images//home-page/webinar.svg'
                  }
                />
                <Card.Body>
                  <Card.Title className="card-title">Webinars</Card.Title>
                  <Card.Text className="card-text">
                    Watch the latest Webinar on ATTOM Cloud to find out more
                    about the thinking behind ATTOM Cloud and how to get
                    started.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div className="licence">
            <h2 className="upgrade-data">Upgrade for Full Access to the Data You Need</h2>
            <p className="interest-licence">
              Interested in licensing data and solutions targeted to your
              specific business needs? Contact us at 800-659-2877.
            </p>
          </div>
        </Container>
      </div>
      <MainFooter />
    </>
  );
}

export default HomePage;
