import React, { useState, useEffect } from 'react';
import '../style.css';
import { api } from '../utilities/fetch-json';
import message from '../common/message';
import useAppContext from '../utilities/use-app-context';


function WhiteListIP() {
    const [data, setData] = useState([]);
    const [isEdit, setIsEdit] = useState(null);
    const [editMode, setEditMode] = useState(null);
    const { currentUser, config } = useAppContext();
    const [editRow, setEditRow] = useState(false)
    const [startIp, setStartIp] = useState('')
    const [endIp, setEndIp] = useState('')
    const [id, setId] = useState('')
    const [isValid, setIsValid] = useState(true)
    const [hideStart, setHideStart] = useState(true)
    const [hideEnd, setHideEnd] = useState(true)
    const [unChangedIp, setUnChangedIp] = useState('')
    const [email, setEmail] = useState(
        currentUser ? currentUser.email : 'Unknown'
    );
    const [defaultIp, setDefaultIp] = useState('');

    useEffect(() => {
        getData()
        getDefaultIp()
    }, [])

    const getData = async () => {
        const json = await api.get(`/api/ip-whitelists/${currentUser.id}`);
        if (json.error) {
            return message.error(json.error);
        }
        if (json.data) {

            setData(json.data)
            setStartIp(json.data[0] ? json.data[0].startIp : null)
            setUnChangedIp(json.data[0] ? json.data[0].startIp : null)
            // setEndIp(json.data[0] ? json.data[0].endIp : null)
            setId(json.data[0] ? json.data[0].id : null)

        }
    }



    const getDefaultIp = async () => {
        const json = await api.get(`/api/ip-whitelists-default`);
        if (json.error) {
            return message.error(json.error);
        }
        if (json.data) {
        setDefaultIp(json.data)
        }
    }

    const ipValidation = async (value: any, type: any) => {

        var ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        if (!value.match(ipformat)) {
            if (type == 'start') {
                setHideStart(false)
            }
            // if (type == 'end') {
            //     setHideEnd(false)
            // }
            setIsValid(true)
        } else {
            if (type == 'start') {
                setHideStart(true)
            }
            // if (type == 'end') {
            //     setHideEnd(true)
            // }
        setIsValid(false)
        }


    }


    const handleIPChange = (val:any) => {
        setStartIp(val)      
        if(val==="" || val===null || val===undefined)
        {
           setStartIp(defaultIp)
        }
      }
  
    const onRowClickEdit = () => {
        setEditRow(true)

        if(!startIp)
        {
           setStartIp(defaultIp)

        }

    }
    const onRowClickCancel = () => {
        setEditRow(false)
        setStartIp(unChangedIp)
        setHideStart(true)
        setIsValid(false)
    }
    const onRowClickSave = async () => {
        setEditRow(false)
        const json = await api.post(`/api/ip-whitelists`, { id: id, userId: currentUser.id, ruleName: email, startIp: startIp, endIp: startIp });
        if (json.error) {
            return message.error(json.error);
        }
        if (json.data) {

            // setData(json.data)
            setStartIp(json.data[0].startIp)
            // setEndIp(json.data[0].endIp)
            setId(json.data[0].id)
            setUnChangedIp(json.data[0] ? json.data[0].startIp : null)
            return message.success("Ip whitelisted")

        }


    }

    const updateCellOneData = (id: any) => {
        console.log(id, editMode)
        setIsEdit(null)
    }

    const updateCellTwoData = (id: any) => {
        console.log(id, editMode)
        setIsEdit(null)
    }

    const handleCellEdit = (event: any, id: any) => {
        setEditMode(event)
    }

    const handleEdit = (cell: any) => {
        setEditMode(cell)
        setIsEdit(cell)
    }

    const handleFirstCellOne = (id: number, cell: any) => {
        if (isEdit != cell) {
            return (
                <a onClick={() => handleEdit(cell)}>{cell}</a>
            )
        } else {
            return (
                <div className="edit-cell">
                    <input type="text" value={editMode != '' ? editMode : cell} onChange={(e) => handleCellEdit(e.target.value, id)} onBlur={() => updateCellTwoData(id)} />
                    <div>
                        <button onClick={() => updateCellOneData(id)}>
                            <img src="/assets/images/tick.svg" />
                        </button>
                        <button onClick={() => handleCancelEdit}>
                            <img src="/assets/images/cancel.svg" />
                        </button>
                    </div>
                </div>
            )
        }
    }

    const handleFirstCellTwo = (id: number, cell: any) => {
        if (isEdit != cell) {
            return (
                <a onClick={() => handleEdit(cell)}>{cell}</a>
            )
        } else {
            return (
                <div className="edit-cell">
                    <input type="text" value={editMode != '' ? editMode : cell} onChange={(e) => handleCellEdit(e.target.value, id)} onBlur={() => updateCellTwoData(id)} />
                    <div>
                        <button onClick={() => updateCellTwoData(id)}>
                            <img src="/assets/images/tick.svg" />
                        </button>
                        <button onClick={() => handleCancelEdit}>
                            <img src="/assets/images/cancel.svg" />
                        </button>
                    </div>
                </div>
            )
        }
    }

    const handleCancelEdit = () => {
        setEditMode(null)
        setIsEdit(null)
    }

    return (
        <>
            <div>
                {/* <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-border"> */}
                                <div className="direct-heading"><span>Whitelist IPs</span></div>
                                <p>Add these IP addresses to allow access through ATTOM's firewall.<br /> Visit our <a style={{ color: '#EB4734' }} href="https://cloud-help.attomdata.com/" target="_blank">online help center</a> for detailed instructions.</p>
                                <table className="table table-hover table-ui editable-table">
                                    <thead>
                                        <tr className="table-header">
                                            <th className="border-top-none">Rule Name</th>
                                            <th className="border-top-none">IP</th>
                                            {/* <th className="border-top-none">End IP</th> */}
                                            {editRow ? <th className="border-top-none"></th> : ''}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            // data.map((item: any) => {
                                            //     return (
                                            //         <tr key={item.id}>
                                            //             <td>{currentUser.email }</td>
                                            //             <td>{handleFirstCellOne(item.id, item.startIp)}</td>
                                            //             <td>{handleFirstCellTwo(item.id, item.endIp)}</td>
                                            //         </tr>
                                            //     )
                                            // })
                                            !editRow ?
                                                <tr key="12" onClick={onRowClickEdit} className="hover">
                                                    <td>{currentUser.email}</td>
                                                    <td>{startIp}</td>
                                                    <td>&nbsp;</td>
                                                    {/* <td>{endIp}</td> */}
                                                </tr>

                                                :
                                                <tr key="23">
                                                    <td>{currentUser.email}
                                                    <input type="hidden"
                                                            id="id"
                                                            name="id"
                                                            onChange={(
                                                                e: React.ChangeEvent<HTMLInputElement>
                                                            ) => setId(e.target.value)}
                                                            value={id} />
                                                    </td>
                                                     <td>
                                                        <input type="text"
                                                            className="form-control"
                                                            id="startip"
                                                            placeholder="IP"
                                                            name="startip"
                                                            onChange={(
                                                                e: React.ChangeEvent<HTMLInputElement>
                                                            ) => handleIPChange(e.target.value)}
                                                            value={startIp}
                                                            onBlur={(e) => {
                                                                ipValidation(e.target.value, 'start');
                                                            }}
                                                        />
                                                        <span hidden={hideStart} className="error-msg">Please enter valid IP</span>

                                                        </td>
                                                    {/* <td>
                                                        <input type="text"
                                                            className="form-control"
                                                            id="endip"
                                                            placeholder="End IP" name="endip" onChange={(
                                                                e: React.ChangeEvent<HTMLInputElement>
                                                            ) => setEndIp(e.target.value)} value={endIp}
                                                            onBlur={(e) => {
                                                                ipValidation(e.target.value, 'end');

                                                            }}
                                                        />
                                                        <span hidden={hideEnd} className="error-msg">Please enter valid IP</span>
                                                    </td> */}
                                                    <td> <div className="edit-cell">
                                                        <button disabled={isValid} className="edit-button" onClick={onRowClickSave}>
                                                            <img className="edit-img" src="/assets/images/tick.svg" />
                                                        </button>
                                                        <button className="edit-button" onClick={onRowClickCancel}>
                                                            <img className="edit-img" src="/assets/images/cancel.svg" />
                                                        </button>
                                                    </div></td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            {/* </div>
                        </div>
                    </div>
                </div> */}
                <br/>
            </div>
        </>
    );
}

export default WhiteListIP;
