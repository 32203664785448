import React, { useState, useEffect } from 'react';
import '../style.css';
import { api } from '../utilities/fetch-json';
import message from '../common/message';
import useAppContext from '../utilities/use-app-context';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import CloseIcon from 'mdi-react/CloseIcon';
import IconButton from '../common/IconButton';
import SpinKitCube from '../common/SpinKitCube';


function ChangePassword(props: any) {

    const { currentUser, config } = useAppContext();
    const overlayRef = React.useRef(null);
    const contentRef = React.useRef(null);
    const [showDialog, setShowDialog] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [currentPassword, setCurrentPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState({
        lowerCase: false,
        upperCase: false,
        number: false,
        specialCharacter: false,
        totalLength: false,
        containEmail: true,
        currentpasswordMatch: false
    });
    const [displayError,  setDisplayError] = useState(false)
    const [, setRefreshState] = useState({})
    const [currentPassError,setCurrentPassError]=useState('')
    const [confirmPassError,setConfirmPassError]=useState('')


    const hasLowercase = /[a-z]/g;
    const hasUppercase = /[A-Z]/g;
    const hasNumbers = /[0-9]/g;
    const hasSpecialChar = /[^a-zA-Z0-9\s]/g;

    useEffect(() => {
        if (props.show) {
            setShowDialog(true);
        }
    }, [props.show]);

    useEffect(() => {
        if(hasLowercase.test(password)){
            isValid.lowerCase = true
        } else {
            isValid.lowerCase = false
        }
        if(hasUppercase.test(password)){
            isValid.upperCase = true
        } else {
            isValid.upperCase = false
        }
        if(hasNumbers.test(password)){
            isValid.number = true
        } else {
            isValid.number = false
        }
        if(hasSpecialChar.test(password)){
            isValid.specialCharacter = true
        } else {
            isValid.specialCharacter = false
        }
        if(password.length>=8){
            isValid.totalLength = true
        } else {
            isValid.totalLength = false
        }
        if(!validateEmail(currentUser.email, password) && password){
            isValid.containEmail = false
        } else {
            isValid.containEmail = true
        }
        if (
            currentPassword &&
            password &&
            currentPassword.toLowerCase() !== password.toLowerCase()
          ) {
            isValid.currentpasswordMatch = false
        } else {
            isValid.currentpasswordMatch = true
        }
        setIsValid(isValid)
        setRefreshState({})   
    }, [password, currentPassword]);

    const close = () => {
        setIsValid({
            lowerCase: false,
            upperCase: false,
            number: false,
            specialCharacter: false,
            totalLength: false,
            containEmail: true,
            currentpasswordMatch: false
        })
        setConfirmPassError('')
        setCurrentPassError('')
        setDisplayError(false)
        setCurrentPassword('')
        setConfirmPassword('')
        setPassword('')
        props.onClose()
    };

    const validateEmail = (email:string, input: string) => {
        let forbiddenSequence = email.substring(0, 3);;
        return input.includes(forbiddenSequence);
    };

    const validateForm = () => {
    
        let error=false
        setDisplayError(isValid.lowerCase && isValid.upperCase && isValid.number && isValid.specialCharacter && isValid.totalLength && !isValid.containEmail && !isValid.currentpasswordMatch ? false : true)
       if(!currentPassword ){
        setCurrentPassError('Current Password is required')
        error=true
       }
       if(!confirmPassword ){
        setConfirmPassError('Confirm Password is required')
        error=true
       }
       if(password !== confirmPassword){
       setConfirmPassError(`Password doesn't match`)
       error=true
       }
       if(error || displayError){
           return false 

       }else{

           return true
       }
       
    };

    const changeUserPassword = async () => {
        if(!validateForm())
        return
       
        setLoading(true);
        const json = await api.post('/api/changepassword', { id: currentUser.userIdB2C,appId:currentUser.id, password: password,currentPassword:currentPassword,email:currentUser.email });
        if (json.error) {
            setLoading(false);
            setCurrentPassError(json.error)
            return;
            // return message.error(json.error);
        }
        if (json.data) {
            setLoading(false);
            setIsValid({
                lowerCase: false,
                upperCase: false,
                number: false,
                specialCharacter: false,
                totalLength: false,
                containEmail: true,
                currentpasswordMatch: false
            })
            setConfirmPassError('')
            setCurrentPassError('')
            setDisplayError(false)
            setCurrentPassword('')
            setConfirmPassword('')
            setPassword('')
            props.passSuccessMsg(true)
            props.onClose()
            // return message.success('Password Changed');

        } else {
            setLoading(false);
            return message.error('Something went wrong, Please try again');
        } 
        
    };

    const checkValidation = (type: string) => {
        if (type === 'blur') {
            setDisplayError(isValid.lowerCase && isValid.upperCase && isValid.number && isValid.specialCharacter && isValid.totalLength && !isValid.containEmail && !isValid.currentpasswordMatch  ? false : true)
        }
        if (type === 'focus') {
            setDisplayError(true)
        }
    }

   const removeErrorMsg = (controls: string) => {
     if (controls == 'confirmPassword') {
       setConfirmPassError('');
     } else if (controls == 'currentPassword') {
       setCurrentPassError('');
     }
   };


    const validationErrorHandlingElement = () => {
        return (
            displayError ? (
                <div className='pass-container'>
                    <p className={isValid.currentpasswordMatch ? 'error_case' :  'success_case'}>Different from current password</p>

                    <p className={isValid.totalLength ? 'success_case' : 'error_case' }>Minimum 8 characters</p>

                    <p className={isValid.lowerCase ? 'success_case' : 'error_case' }>A lowercase letter</p>

                    <p className={isValid.upperCase ? 'success_case' : 'error_case' }>An uppercase letter</p>

                    <p className={isValid.number ? 'success_case' : 'error_case' }>A number</p> 

                    <p className={isValid.specialCharacter ? 'success_case' : 'error_case' }>A special character</p>

                    <p className={isValid.containEmail ? 'error_case' :  'success_case'}>Cannot contain words found in email</p>
                </div>
            ) : <div />
        )
    }

    return (
        <>
            {loading ? <div className="loading-div"><SpinKitCube /></div> : ''}

            <DialogOverlay
                ref={overlayRef}
                style={{ background: 'hsla(0, 100%, 100%, 0.9)' }}
                isOpen={props.show}
                // onDismiss={props.onClose}
            >
                <DialogContent
                    aria-label="Announcement"
                    ref={contentRef}
                    style={{
                        boxShadow: '0px 10px 50px hsla(0, 0%, 0%, 0.33)',
                        width: '40vw',
                    }}
                    className='dialog-content'
                >
                    <IconButton onClick={close} style={{ float: 'right', border:'none', background:'none' }}>
                        <CloseIcon />
                    </IconButton>
                    <div className="welcome-title-popup">
                        <p style={{ textAlign: "center" }}><span className="welcome-title">Change your password</span></p>
<form className="justify-content-center">
                        <div className="row" style={{display:'block'}}>    
                            <div className="form-group">
                                <div className="col-sm-9 passwordChange-dialogueBox">
                                    {/* <p className='input_labels'>Confirm your Current Password</p> */}
                                    <input type="password"
                                        className="form-control w-100"
                                        id="currentPassword"
                                        placeholder="Current Password"
                                        name="CurrentPassword"
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => setCurrentPassword(e.target.value)}
                                        onFocus={() => removeErrorMsg('currentPassword')}

                                    />
                                    {currentPassError && <p className='error_case pass-container'>{currentPassError}</p>}
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-sm-9 passwordChange-dialogueBox">

                                    <input type="password"
                                        className="form-control w-100"
                                        id="password"
                                        placeholder="New Password"
                                        name="password"
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => setPassword(e.target.value)}
                                        onBlur={() => checkValidation('blur')}
                                        onFocus={() => checkValidation('focus')}
                                    />
                                {validationErrorHandlingElement()}
                                </div>
                            </div>
                           
                            <div className="form-group">
                                <div className="col-sm-9 passwordChange-dialogueBox">
                                    <input  type="password"
                                        className="form-control w-100"
                                        id="ConfirmPassword"
                                        placeholder="Confirm Password"
                                        name="ConfirmPassword"
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => setConfirmPassword(e.target.value)}
                                        onFocus={() => removeErrorMsg('confirmPassword')}

                                    />
                                    {confirmPassError && <p className='error_case pass-container'>{confirmPassError}</p>}
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group" style={{margin:'0 auto'}}>
                                    <div className="col">
                                        <input
                                        style={{
                                            backgroundColor: '#eb4734',
                                            borderColor: '#eb4734'
                                        }}
                                            type="button"
                                            className="btn btn-primary"
                                            value="Save New Password"
                                            onClick={changeUserPassword}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>

                </DialogContent>
            </DialogOverlay>
        </>
    );
}

export default ChangePassword;
