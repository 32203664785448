import React, { useState } from 'react'
import { Container } from 'react-bootstrap';
import '../style.css'

type purchaseProps = {
    dataElements?: any,
}


const DataElementsLink = (props: purchaseProps) => {

    const dataElement = (props.dataElements && props.dataElements.entitlements && props.dataElements.entitlements.length>0)?props.dataElements.entitlements:null
    const openLinkHandler = (data: string) => () => {
      if (data !== null) {
        return window.open(encodeURI(data), 'help');
      }
    };
    return (
        <>
        <section className="detail-bgs">
            Learn more about each of the data elements in this Solution Pack:
                <div className='detail-containers_data'>
                {dataElement && dataElement?.sort((a: any, b: any) => a.entitlementName.localeCompare(b.entitlementName))?.map((item: any, i:any) =>  <p key={i}  className={`data_items_list ${item.dataElementLink !== null && 'data_link_cursor'}`}  onClick={openLinkHandler(item.dataElementLink)}>{item.entitlementName}</p>)}
                </div>
    </section>
</>
    )
}

export default DataElementsLink;






