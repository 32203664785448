import React from 'react';
import styles from './CloudObject.module.css';
import '../style.css';
import { Container } from 'react-bootstrap';
// import CloudModal from './Modal/CloudModal';
import { useState } from 'react';
import CloudObjectsModel from './CloudObjectsModel';

type cloudObjectProps = {
  cloudObjects: any
  solutionDetails: any
};
const CloudObject = (props: cloudObjectProps) => {
  const [showModal, setShowModel] = useState(false);

  const showModalHanlder = () => {
    setShowModel(true);
  };
  const closeModalHanlder = () => {
    setShowModel(false);
  };

  const { cloudObjects ,solutionDetails} = props;

  return ( 
    <>
   {cloudObjects && <Container className={`meta-container ${styles.mainContainer}`}>
      {cloudObjects?.entitlements?.length > 0  ? (<div className={styles.cloudDesc}>
        <p className={styles.heading}>SQL Tables and Views</p>
        <div className={styles.ObjectsSql}>
          The {solutionDetails?solutionDetails.subscriptionName:''} Solution Pack includes direct access to the following
          SQL objects: 
          {cloudObjects &&
            cloudObjects?.entitlements?.sort((a: any, b: any) => a.defaultValue.localeCompare(b.defaultValue))?.map((item: any,index:any) => ( 
            <p key={index} className={styles['list-row']}>
            {' '+ item.defaultValue}{index === (Object.keys(cloudObjects.entitlements).length) - 1 ?". " : ", "}
            {index === (Object.keys(cloudObjects.entitlements).length) - 2 ?" and " : ""}
        </p>
            ))}
          <span className={styles.detailmodel} onClick={showModalHanlder}>
            See details
          </span>
        </div>
        {showModal && <CloudObjectsModel cloudObjects={cloudObjects} closeModalHanlder={closeModalHanlder} solutionDetails={solutionDetails} showModal={showModal}/>}
      </div>) : ''}
    </Container> }
    </>
  );
};

export default CloudObject;
