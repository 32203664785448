import React from 'react'
import '../style.css'
const FreeTrail = () => {
  return (
    <>
    <div style={{"backgroundColor": "#424242", 'marginTop': '24px'}} className="trail-main-np"><h3 className="trial-heading-np">Your Free Trial includes 30 days access to this Solution Pack.</h3>
    <p className="trial-text-np">To extend your usage, contact Sales at (800) 659-2877</p> </div>
    
     </>
  )
}

export default FreeTrail;