import React, { useState } from 'react';
import Logo from './Logo';
import AppMenu from './AppMenu';
import MainHeaderCloud from './MainHeaderCloud';
import useAppContext from '../utilities/use-app-context';
import HistoryButton from './HistoryButton';
import APIExplorerButton from './APIExplorerButton'
import SolutionCatalogButton from './SolutionCatalogButton'
import { api } from '../utilities/fetch-json';
import { Navbar, NavDropdown, Container, Nav, CloseButton } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import GoToTop from '../utilities/GoToTop';
import SolutionPackStatusPopup  from  '../solutionPack/SolutionPackStatusPopup'
import { useEffect } from 'react';
import HideBeacon from '../utilities/HideBeacon';
import PropertyReportsButton from './PropertyReportsButton';


function Mainheader(props: any) {
  let isCloudActive = false, isVisualizeActive = false, isProprtyReports = false, isSolutionCatlog=false
  const [solutionPackStatus, setSolutionPackStatus] = useState(null)
  const { currentUser,config } = useAppContext();
  const history = useHistory();


  const isIE = props["isIE"];
  const [crossBtn, setCrossBtn] = useState(false);
  const url = window.location.href;
  if (url.includes("/queries") || url.includes("/usagemetrics") || url.includes("documentation")) {
    isCloudActive = true;
    isVisualizeActive = false;
    isSolutionCatlog=false
  }
  else if (url.includes("/propertyreports") || url.includes("/propertynavigator")) {
    isCloudActive = false;
    isSolutionCatlog=false
    isProprtyReports = true;
  }
  else if (url.includes("/solutioncatalog")) {
    isCloudActive = false;
    isProprtyReports = false;
    isSolutionCatlog=true
  }
  const toggelCrossbtn = () => {
    setCrossBtn(true);
  }
  const showHeaderFn = () => {
    const query = new URLSearchParams(window.location.search);
    const header = query.get('h');
    if(header=='true'){
      return true
    }else if(url.includes("/pn")){
      return true
    }else{
      return false

    }
 
  }
async function disableSignIn() {
  const json = await api.get(`/api/block-user-signin/${currentUser.userIdB2C}`)
if(json.error){
  console.log(json.error)
}
}


  useEffect(() => {
 if(currentUser.isActiveSolution){
  setSolutionPackStatus(currentUser.isActiveSolution)
 } else {
  setSolutionPackStatus(currentUser.isActiveSolution)
  // disableSignIn()
 }
  },[currentUser])
  const CloseButton = () => {

    return <>

      <Navbar.Toggle style={{ width: '0' }} onClick={btnClose} className="text-end border-0 mb-3" aria-controls="responsive-navbar-nav" >
        <span className="closebtnsize text-end "><img src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/x-lg.svg'} alt="Home" /></span></Navbar.Toggle>

    </>

  }


  const btnClose = () => {
    setCrossBtn(false);

    return <>

      <Navbar.Toggle style={{ width: '0' }} className="text-end border-0 mb-3" aria-controls="responsive-navbar-nav" >
      </Navbar.Toggle>
    </>
  }
  const handleClick = () => history.push('/home');
  const userProfile = () => history.push('/home');
  return (
    <div
      style={{
        width: '100%',
        color: '#fff',
        backgroundColor: '#1d1d1d',
        display:showHeaderFn()?'none':'',
        borderBottom: '1px solid rgb(204, 204, 204)',
      }} className="pt-1 "
    >
      <GoToTop/>
     <HideBeacon />
     {!solutionPackStatus && <SolutionPackStatusPopup /> }
      <div style={{ display: 'flex', backgroundColor: '#1d1d1d' }} className=""  >
        <div className="container-fluid position-relative mx-0 px-0 " style={{ backgroundColor: '#1d1d1d', marginTop:'10px' }}  >
          <Navbar collapseOnSelect expand="lg" className="p-0 tab-nav">
            {/* variant="dark" */}
            <div className=" tab-menu d-lg-flex" >
              <div className="navbar-dark d-lg-flex p-2 " style={{ backgroundColor: '#1d1d1d' }}>
                <Navbar.Brand className="pr-lg-5"><Logo /></Navbar.Brand>
                <img src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/home-icon-white.svg'} alt="Home" className="hover mt-lg-1 mt-md-n3 mt-n4 " width="25" height="25" onClick={handleClick} />
                {/* <img src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/person-fill.svg'} alt="Home" className="hover userprofile-icon  " width="25" height="25" /> */}
               <span className="userprofile-icon"> <AppMenu /></span>
                {crossBtn ? CloseButton() : <>

                  <Navbar.Toggle onClick={toggelCrossbtn} className="text-end border-0 mb-3" aria-controls="responsive-navbar-nav" style={{ width: '0' }} />


                </>}
              </div>


                <Navbar.Collapse className="bg-sm-white pt-lg-1 bg-md-white mt-lg-n1" id="responsive-navbar-nav" >
                  <Nav className="me-auto">
                    <Nav.Link className="text-start"><MainHeaderCloud isActive={isCloudActive} /></Nav.Link>
                    <Nav.Link> <APIExplorerButton /> </Nav.Link>
                  </Nav>
                  <Nav>
                    <Nav.Link >
                      <PropertyReportsButton isActive={isProprtyReports} isIE={isIE} />
                    </Nav.Link>
                    <Nav.Link ><SolutionCatalogButton isActive={isSolutionCatlog}/></Nav.Link>

                  </Nav>
                  <Nav >
                    <Nav.Link >
                      <HistoryButton />
                    {/* <hr className="mx-4 d-lg-none d-block"></hr> */}
                    </Nav.Link>

                  </Nav>

                </Navbar.Collapse> 
              {/* <AppHeaderSpacer grow />
                  <AppHeaderUser />
        <AppMenu /> */}

            </div>
          </Navbar>
        </div>
        {/* <MainHeaderCloud isActive={isCloudActive} /> */}
        {/* <MainHeaderVisualize isActive={isVisualizeActive} /> */}
        {/* <APIExplorerButton /> */}
        {/* <SolutionCatalogButton /> */}
        {/* <PropertyReports isActive={isProprtyReports} cookieEnable={cookieEnable} isIE={isIE}/>
        <HistoryButton />
        <AppHeaderSpacer grow />
        <AppHeaderUser />
         */}
      </div>
    </div>
  );
}

export default React.memo(Mainheader);
