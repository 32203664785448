import React, { useEffect, useState } from 'react';
import Mainheader from '../app-header/MainHeader';


function CheckThirdPartyCookie() {

  const [isIE, setIsIE] = useState(false);

  useEffect(() => {
    var ua = window.navigator.userAgent;
    var isIE = /MSIE|Trident/.test(ua);
    if (isIE) {
      //IE specific code goes here
      setIsIE(true)

    } else {
      setIsIE(false)
    }
  }, []);


  return (<div>
      <Mainheader isIE={isIE} />
  </div>)

}

export default CheckThirdPartyCookie;
