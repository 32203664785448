import Button from '../common/Button';
import React, { useEffect, useState } from 'react';
import styles from '../common/Button.module.css';
import QueryListDrawer from '../queries/QueryListDrawer';

function QueryListButton(props:any) {
  const [showQueries, setShowQueries] = useState(false);

useEffect(() => {
  setShowQueries(props.openQuery);
}, [props.openQuery]);

  return (
    <>
      <Button className={styles.subheaderbutton +' menu'} onClick={() => setShowQueries(true)}>
        Queries
      </Button>
      <QueryListDrawer
        visible={showQueries}
        onClose={() => setShowQueries(false)}
        preload={props.openQuery}
      />
    </>
  );
}

export default React.memo(QueryListButton);
