import React, {useEffect, useState} from 'react';
import { connect } from 'unistore/react';
import Button from '../../common/Button';
import { connectConnectionClient } from '../../stores/connections';
import { runQuery } from '../../stores/queries';

function mapStateToProps(state: any) {
  const { isRunning,query } = state;
  return {
    isRunning,
    query
  };
}

const ConnectedToolbarRunButton = connect(mapStateToProps, (store) => ({
  connectConnectionClient: connectConnectionClient(store),
  runQuery: runQuery(store),
  // @ts-expect-error ts-migrate(2345) FIXME: Property 'runQuery' is missing in type '{ isRunnin... Remove this comment to see the full error message
}))(React.memo(ToolbarRunButton));

type Props = {
  isRunning: boolean;
  query: any;
  connectConnectionClient: (...args: any[]) => any;
  runQuery: (...args: any[]) => any;
};

function ToolbarRunButton({
  isRunning,
  query,
  connectConnectionClient,
  runQuery,
}: Props) {

  const [runData, setRunData] = useState(false)
 
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('run') && query && query.queryText.length > 0) {
      setRunData(true);
    }
  }, [query]);

  useEffect(() => {
    setRunData(false);
  }, []);

  useEffect(() => {
    if (runData) {
      runQueryAuto();
    }
  }, [runData]);

  const runQueryAuto = async () => {
    await connectConnectionClient();
    await runQuery();
  };


  return (
    <Button
      className="run-btn"
      onClick={async () => {
        await connectConnectionClient();
        await runQuery();
      }}
      disabled={isRunning}
    >
      Run
    </Button>
  );
}
export default ConnectedToolbarRunButton;
