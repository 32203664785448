import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactHeap from 'reactjs-heap';
import useAppContext from '../utilities/use-app-context';
import { api } from '../utilities/fetch-json';
import { search } from '@metrichor/jmespath';

const useHeapTracker = () => {
  const location = useLocation();
  const { currentUser, config } = useAppContext();
  const url = window.location.href;

  useEffect(() => {
    if (window.location.href.includes('cloud.attomdata.com')) {
      ReactHeap.initialize('528476122');
      if (window.heap && !url.includes('/termsofuse')) {
        window.heap.identify(currentUser.email);
        getProfile();
      }
    }
  }, []);

  const getProfile = async () => {
    const json = await api.post('/api/getuser', { id: currentUser.userIdB2C });
    if (json.error) {
      //   return message.error(json.error);
    }
    if (json.data) {
      window.heap.addUserProperties({
        Name: json.data.givenName + ' ' + json.data.surname,
        Company: search(
          json.data,
          `extension_${config.b2cExtensionId}_company`
        ),
        Industry: search(
          json.data,
          `extension_${config.b2cExtensionId}_industry`
        ),
        Position: json.data.jobTitle,
      });
    }
    // else
    // {
    //   return message.success('Something went wrong');

    // }
  };
};

export default useHeapTracker;
