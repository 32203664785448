import React ,{useEffect,useState }  from 'react';
import { models} from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import './visualize.css';
import AppHeader from '../app-header/AppHeader';
import { api } from '../utilities/fetch-json';
import message from '../common/message';
import CheckThirdPartyCookie from '../utilities/CheckThirdPartyCookie';


export interface IToken
{
  data?: any;
  links?: string;
  error?: string;
}
// Root Component to demonstrate usage of wrapper component
function Visualize() {
  const [reportData, setReportData] = useState<IToken>({});
  const [showReport, setShowReport] = useState(false);

  useEffect(() => {
    document.title = 'ATTOM Cloud';
    getReport();
    const interval = setInterval(() => {
     getReport();
    }, 58 * 60 * 1000);
    return () => clearInterval(interval);
   
  }, []);



  async function getReport() {
    const json = await api.get(`/api/powerbi/visualize`);

    if (json.error) {
      message.error(json.error);
    } else {
      setReportData(json);
      setShowReport(true);
    }
  }

  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CheckThirdPartyCookie />
      <AppHeader />
      {showReport ? (
        <PowerBIEmbed
          embedConfig={{
            type: 'report', // Supported types: report, dashboard, tile, visual and qna
            id: reportData.data ? reportData.data.embedUrl[0].reportId : null,
            embedUrl: reportData.data
              ? reportData.data.embedUrl[0].embedUrl
              : '',
            accessToken: reportData.data ? reportData.data.accessToken : '',
            tokenType: models.TokenType.Embed,
          }}
          cssClassName={'report-style-class'}
        />
      ) : (
        <div className="loadingMsg">Loading report...</div>
      )}
    </div>
  );
}

export default Visualize;
