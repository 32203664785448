import React from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog';
import CloseIcon from 'mdi-react/CloseIcon';
import IconButton from '../common/IconButton';

type propsModalHandler = {
    showDialog : boolean
    closeClickHandler : () => void
}

const Modals = (props:propsModalHandler) => {
const overlayRef = React.useRef(null);
const contentRef = React.useRef(null);
  return (
    <DialogOverlay
    ref={overlayRef}
    style={{ background: 'rgb(0 0 0/ 60%)'}}
    isOpen={props.showDialog}
  // onDismiss={close}
  >
    <DialogContent
      aria-label="Announcement"
      ref={contentRef}
      style={{
        boxShadow: '0px 10px 50px hsla(0, 0%, 0%, 0.33)'
      }}
      className="purchaseDialogMetaData"
    >

   <IconButton  className="btn-close meta-button">
        <CloseIcon onClick={props.closeClickHandler} size={'50px'} />
      </IconButton>

<br/>
<p style={{fontWeight:700 }} className="meta-overlay">This content is not available as part of your free trial.</p>
<p className="meta-overlay">To access it for evaluation, please contact us at (800) 659-2877.</p>

    </DialogContent>
  </DialogOverlay> 
  )
}

export default Modals