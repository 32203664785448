
import React, { useEffect, useState } from 'react';
import useAppContext from '../utilities/use-app-context';
import '../style.css'
import './solutionPack.css'
import { api } from '../utilities/fetch-json';
// import useGaTracker from '../common/useGaTracker'
import useHeapTracker from '../common/useHeapTracker'
import CheckThirdPartyCookie from '../utilities/CheckThirdPartyCookie';
import SpinKitCube from '../common/SpinKitCube';
import SolutionDetail from './SolutionDetail';
import QueryData from './QueryData'
import MetaData from './MetaData';
import SampleAnalytics from './SampleAnalytics';
import DiveDeeper from './DiveDeeper';
import FreeTrial from './FreeTrial';
import MainFooter from '../app-footer/MainFooter'
import message from '../common/message';
import CloudObject from './CloudObject';

type Props = {
  solutionId: string;
};

function SolutionPackHome(props: Props) {

  const { currentUser, config } = useAppContext();
  const [isPurchased, setIsPurchased] = useState(true)
  const [solutionDetails, setSolutionDetails] = useState<any>([])
  const [dataElements, setDataElements] = useState([])
  const [queryData, setQueryData] = useState<any>()
  const [metaData, setMetaData] = useState([])
  const [sampleAnalytics, setSampleAnalytics] = useState([])
  const [diveDeeper, setDiveDeeper] = useState([])
  const [susbsIcon, setSubsIcon] = useState<any>()
  const [cloudObjects, setCloudObjects] = useState([])

  const [loading, setLoading] = useState(true)



  useHeapTracker();
  // useGaTracker();

useEffect(() => {
  if(solutionDetails?.subscriptionName){
    document.title = solutionDetails?.subscriptionName
    }else {
      document.title = 'Loading'
    }
},[solutionDetails])
  useEffect(() => {
 
    getSubscription()
  }, [])



  const getSubscription = async () => {
    const json = await api.get(`/api/solution-entitlements?solutionId=${props.solutionId}&uid=${currentUser.id}`);
    if (json.data) {

      let query = await api.get(`/api/queries?limit=200&ownedByUser=false&solutionpack=true&subscriptionCode=${props.solutionId}`);
      // console.log(query)
      let subscriptions = json.data.subscriptions
      let filterSusbscription=subscriptions.find((data: any) => data.subscriptionCode.toLowerCase() == props.solutionId)
      setSolutionDetails(filterSusbscription)
      filterSusbscription && filterSusbscription.clientSubscriptionCode ? setIsPurchased(false):setIsPurchased(true)
      // setIsPurchased(true) //Need to change for Purchased Susbscription
      let solutionpackQuery=filterSusbscription.components.find((data: any) => data.componentType ==='Queries')
     let result=''
      if(solutionpackQuery && solutionpackQuery.entitlements){
       result = solutionpackQuery.entitlements.map((pack:any) => {
        let match = query.data.find((data:any) => data.entitlementsId == pack.entitlementId);
        return {
          entitlementName:pack.entitlementName,
          entitlementCode : pack.entitlementCode, 
          entitlementDescription: pack.entitlementDescription,
          entitlementType:pack.entitlementType,
          entitlementId:pack.entitlementId,
          sequenceNumber:pack.sequenceNumber,
          downloadFile:pack.downloadFile,
          // queryText : match && match.queryText?match.queryText:null,
          queryId : match && match.id?match.id:null,
        }
    })
    setQueryData({componentDescription: solutionpackQuery.componentDescription,componentName: solutionpackQuery.componentName,componentType:solutionpackQuery.componentType,entitlements:result})
  }
      setMetaData(filterSusbscription.components.find((data: any) => data.componentType ==='Meta Data'))
      setSampleAnalytics(filterSusbscription.components.find((data: any) => data.componentType ==='Analytics'))
      setDiveDeeper(filterSusbscription.components.find((data: any) => data.componentType ==='P-Articles'))
      setDataElements(filterSusbscription.components.find((data: any) => data.componentType ==='Data Element'))
      setCloudObjects(filterSusbscription.components.find((data: any) => data.componentType ==='ATTOM Cloud Objects'))
      setLoading(false)
      if(filterSusbscription.solutionPackImageName){
        getIcon(filterSusbscription.solutionPackImageName).then( response => {
          setSubsIcon(response)
        });
      }
    
      
    }else {
      setLoading(false)
      return message.error("No Data found");
    }

  }
  const getIcon = async (icon: any) => {
    const json = await api.post(`/api/get-icon`, { icon: icon });
    if (json.data) {
      return json.data;
    }
  };

 
  return (
    <>
      <div>
        <CheckThirdPartyCookie />
        <div style={{ width: '100%', margin: 'auto' }}>
        {loading ? <div className="loading-div"><SpinKitCube /></div> : ''}
       {!loading && <div><SolutionDetail isPurchased={isPurchased} solutionDetails={solutionDetails} dataElement={dataElements} susbsIcon={susbsIcon}/>
        <QueryData queryData={queryData} solutionDetails={solutionDetails}/>
        <CloudObject cloudObjects = {cloudObjects} solutionDetails={solutionDetails}/>
        <MetaData metaData={metaData} isPurchased={isPurchased}/>
        <SampleAnalytics sampleAnalytics={sampleAnalytics} solutionDetails={solutionDetails} isPurchased={isPurchased}/>
        <DiveDeeper divedeeper={diveDeeper} dataElements={dataElements} />
        <FreeTrial isPurchased={isPurchased} />
          <MainFooter /></div> }
        </div>
      </div>
    </>
  );
}

export default SolutionPackHome;