import React, { useState } from 'react'
import { Container } from 'react-bootstrap';
import useAppContext from '../utilities/use-app-context';
import { useHistory } from 'react-router-dom';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import CloseIcon from 'mdi-react/CloseIcon';
import IconButton from '../common/IconButton';
import '../style.css'

type purchaseProps = {
    isPurchased: boolean,
    solutionDetails:any,
    dataElement?: any,
    susbsIcon?:any

}


const SolutionDetail = (props: purchaseProps) => {
    const { config, currentUser } = useAppContext();
    const [showDetail, setShowDetail] = useState(false)
    const history = useHistory()
    const downArrow = process.env.PUBLIC_URL + config.baseUrl + '/assets/images/downArrow.svg'
    const upArrow = process.env.PUBLIC_URL + config.baseUrl + '/assets/images/upArrow.svg'
    const back = "< ";
    const overlayRef = React.useRef(null);
    const contentRef = React.useRef(null);
    const [showDialog, setShowDialog] = React.useState(false);

    const showDetailHandler = () => {
        setShowDetail((val) => !val)
    }
    const backToSolutionCatalog = () => {
        history.push('/solutioncatalog')
    }
    const closeAction = async (action:boolean) => {
        setShowDialog(action);
    
    }
    const dataElement = (props.dataElement && props.dataElement.entitlements && props.dataElement.entitlements.length>0)?props.dataElement.entitlements:null
    const openLinkHandler = (data: string) => () => {
      if (data !== null) {
        return window.open(encodeURI(data), 'help');
      }
    };
    
    return (
        <>
        <section className="detail-bg">
            <Container className="detail-containers">
                <div className='back-button' onClick={backToSolutionCatalog}> {back}  Back to Solution Catalog</div>
                <div className='detail-container'>
                    <div className='detail-img'>
                    {props.solutionDetails.solutionPackImageName?
                <img src={`data:image/svg+xml;base64,${props.susbsIcon?props.susbsIcon:''}`}/>:
                <img src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/SP-Default.svg'} alt="Image not found" />
              }
                      
                    </div>
                    <div className="detail-grid">
                    {props.solutionDetails.solutionType !== null && <div className={props.solutionDetails.solutionType === "Solution Pack" ? 'solution-type-home' :"dataset-type-home"}><p className='solution-type-text'>{props.solutionDetails.solutionType}</p></div>}
                        <h2 className="detail-heading">
                           {props.solutionDetails.subscriptionName}
                            {!props.isPurchased ?
                                <div style={{ cursor: 'pointer' }} onClick={showDetailHandler}> <div className="solution-detail">Details <img className="detail-arrow-img" src={!showDetail ? downArrow : upArrow} alt="detail" /></div></div> :
                                <button className="solution-detail-button" onClick={() => closeAction(true)}>License</button>
                            }
                        </h2>


                        {showDetail && <div className="detail-container-para"><p className="solution-para-text">
                        {props.solutionDetails.subscriptionDesc}
                           
                        </p>
                           {dataElement ? <><div className="show-grid">
                                <div className="solution-para-text"> Data contained in this pack:</div>
                                <div className="grid">
                                    <div className="g-col-4"><ul>
                                      {dataElement && dataElement.map((item: any, i:any) =>  <li key={i} className={`${item.dataElementLink !== null? 'data_link_cursor': ''}`} onClick={openLinkHandler(item.dataElementLink)}>{item.entitlementName}</li>)}
                                        
                                        </ul></div>
                        

                                </div>
                            </div>
                            {props.solutionDetails.deliveryMethod && props.solutionDetails.deliveryMethod !== null  && <div style={{paddingBottom:'25px'}} className="show-grid" >
                            <div className="solution-para-text"> Delivery Methods:</div>
                            <div className="delivery-type-main">
                                {/* <div className="g-col-4"> */}
                                    {props.solutionDetails.deliveryMethod.split(';').map((item: any, i:any) => (
                                        <div key={i} style={{marginLeft:0}} className={`${item === "Bulk" && 'bulk'} ${item === "Cloud" && 'cloud'} ${item === "API" && 'api'} ${item === "Property Navigator" && "propertyNav"} `}><p className='solutionDetail-delivery'>{item}</p></div>
                                    ))}
                                
                                {/* </div> */}
                            </div>
                        </div> }
                        </>
                                :''}
                        </div>}
                        {props.isPurchased? <div className="detail-container-para"><p className="solution-para-text">
                        {props.solutionDetails.subscriptionDesc}
                         
                        </p>
                            {dataElement ? <div className="show-grid">
                                <div className="solution-para-text"> Data contained in this pack:</div>
                                <div className="grid">
                                    <div className="g-col-4"><ul>
                                    {dataElement && dataElement?.sort((a: any, b: any) => a.entitlementName.localeCompare(b.entitlementName))?.map((item: any, i:any) =>  <li key={i} className={`${item.dataElementLink !== null? 'data_link_cursor': ''}`} onClick={openLinkHandler(item.dataElementLink)}>{item.entitlementName}</li>)}</ul></div>
                    

                                </div>
                                
                            </div>
                            
                        :''}
 {props.solutionDetails.deliveryMethod && props.solutionDetails.deliveryMethod !== null  && <div style={{paddingBottom:'25px'}} className="show-grid" >
                                <div className="solution-para-text"> Delivery Methods:</div>
                                <div className="delivery-type-main">
                                    {/* <div className="g-col-4"> */}
                                        {props.solutionDetails.deliveryMethod.split(';').map((item: any, i:any) => (
                                            <div style={{marginLeft:0}} className={`${item === "Bulk" && 'bulk'} ${item === "Cloud" && 'cloud'} ${item === "API" && 'api'} ${item === "Property Navigator" && "propertyNav"} `}><p className='solutionDetail-delivery'>{item}</p></div>
                                        ))}
                                    
                                    {/* </div> */}
                                </div>
                            </div> }
                        </div>
                       :'' }
          
                    </div>
                </div>
            </Container>
            <DialogOverlay
        ref={overlayRef}
        style={{ background: 'rgb(0 0 0/ 60%)' }}
        isOpen={showDialog}
      // onDismiss={close}
      >
        <DialogContent
          aria-label="Announcement"
          ref={contentRef}
          style={{
            boxShadow: '0px 10px 50px hsla(0, 0%, 0%, 0.33)',
            width: '60vw',
          }}
          className="purchaseDialog"
        >
       <IconButton  onClick={() => closeAction(false)} className="btn-close">
            <CloseIcon size={'48px'} />
          </IconButton>

<br/>
<p className='p-6'>For pricing or to purchase a license to this Solution Pack, Call Sales at (800) 659-2877.</p>
  
        </DialogContent>
      </DialogOverlay>     </section>
</>
    )
}

export default SolutionDetail;






