import React, { useState, useEffect } from 'react';
import ConnectionDropDown from '../ConnectionDropdown';
import ToolbarCloneButton from './ToolbarCloneButton';
import ToolbarConnectionClientButton from './ToolbarConnectionClientButton';
import ToolbarFormatQueryButton from './ToolbarFormatQueryButton';
import ToolbarQueryNameInput from './ToolbarQueryNameInput';
import ToolbarRunButton from './ToolbarRunButton';
import ToolbarSaveButton from './ToolbarSaveButton';
import ToolbarShareQueryButton from './ToolbarShareQueryButton';
import ToolbarSpacer from './ToolbarSpacer';
import ToolbarTagsButton from './ToolbarTagsButton';
import ToolbarToggleSchemaButton from './ToolbarToggleSchemaButton';
import localforage from 'localforage';
import {
  selectConnectionId,
  connectConnectionClient,
} from '../../stores/connections';
import { connect } from 'unistore/react';
import useSWR from 'swr';
import '../../style.css';

function Toolbar({
  connectConnectionClient,
  selectConnectionId,
  selectedConnectionId,
}: any) {
  const [selectedTrial, setSelectedTrail] = useState(true);
  const [selectedConn, setSelectedConn] = useState('');
  const [showMore, setShowMore] = useState(true);
  const [hideMsg, setHideMsg] = useState(true);

  let allConn: any = [];

  let { data: connectionsData, mutate } = useSWR('/api/connections');
  const connections = connectionsData || [];

  const toggleMsg = async (val: any) => {
    setShowMore(val);
  };

  
  useEffect(() => {
    let obj = connections.find((data: any) => data.id === selectedConnectionId);

    if (obj && obj.name == 'Standard Trial') {
      setSelectedTrail(true);
      setHideMsg(false);
    }

    if (obj && obj.name == 'ATTOM Cloud') {
      setHideMsg(true);
    } 
    if (obj && obj.name == 'ATTOM Cloud Dedicated') {
      setHideMsg(true);
    }
  }, [selectedConnectionId]);

  const HandleMessage = () => {
    let obj = connections.find((data: any) => data.id === selectedConnectionId);
    if (obj && obj.name == 'Standard Trial') {
      return (
        <div>
          <span className="limited-first-line">
            This trial of ATTOM Cloud is limited to five counties of data. For
            broader data coverage, please contact sales at (800) 659-2877.
          </span>
          {showMore ? (
            <a className="msg-anchor" onClick={() => toggleMsg(false)}>
              Learn more{' '}
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none">
                <path d="M1 1L6.5 7L12 1" stroke="#4578FB" strokeWidth="2" />
              </svg>
            </a>
          ) : (
            <a className="msg-anchor" onClick={() => toggleMsg(true)}>
              Close{' '}
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none">
                <path d="M12 8L6.5 2L1 8" stroke="#4578FB" strokeWidth="2" />
              </svg>
            </a>
          )}
          {!showMore ? (
            <div style={{ marginTop: '15px' }}>
              <span className="limited-second-line">
                Your free trial includes data from these five counties:
              </span>
              <ul className="lists_editor">
                <li>Duval, FL </li>
                <li>Ventura, CA </li>
                <li>Mecklenburg, NC</li>
                <li>Douglas, CO </li>
                <li>Ramsey, MN </li>
              </ul>
            </div>
          ) : (
            ''
          )}
        </div>
      );
    }
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          fontWeight: 'bold',
          fontSize: '12px',
          lineHeight: '22px',
          background: selectedTrial ? '#D7E2EC' : '#F5F1C5',
          justifyContent: 'flex-start',
          alignItems: 'center',
          borderBottom: '1px solid rgb(204, 204, 204)',
          padding: '4px 0px 4px 30px',
          color: '#4F4F4F',
          display:hideMsg ? 'none':'flex'
        }}
      >
        {HandleMessage()}
      </div>
      <div
        style={{
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          padding: 6,
          borderBottom: '1px solid rgb(204, 204, 204)',
        }}
      >
        <div style={{ display: 'flex' }}>
          <ToolbarToggleSchemaButton />
          <ConnectionDropDown />
          <ToolbarSpacer />
          <ToolbarConnectionClientButton />
          <ToolbarSpacer grow />
          <ToolbarQueryNameInput />
          <ToolbarSpacer />
          <ToolbarCloneButton />
          <ToolbarFormatQueryButton />
          <ToolbarSaveButton />
          <ToolbarSpacer grow />
          <ToolbarSpacer />
          <ToolbarRunButton />
          <ToolbarSpacer />
        </div>
      </div>
    </>
  );
}

export default connect(['selectedConnectionId'], (store) => ({
  connectConnectionClient: connectConnectionClient(store),
  selectConnectionId,
}))(Toolbar);
