import { MenuItem } from '@reach/menu-button';
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import IconMenu from '../common/IconMenu';
import { api } from '../utilities/fetch-json';
import useAppContext from '../utilities/use-app-context';
import AboutModal from './AboutModal';
import { useHistory } from 'react-router-dom';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import AccountIcon from 'mdi-react/AccountIcon';

function AppMenu() {
  const { currentUser, config } = useAppContext();
  const [redirectToSignIn, setRedirectToSignIn] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const history = useHistory();
  const handleClick = () => history.push('/profile'); 
  const handleDirectIntegrationClick = () => history.push('/directintegration'); 

  const overlayRef = React.useRef(null);
  const contentRef = React.useRef(null);
  const [showDialog, setShowDialog] = React.useState(false);
  const close = () => {
    setShowDialog(false);
  };

  if (redirectToSignIn) {
    // return <Redirect push to="/signin" />;
    return <Redirect to="/signin" />;
  }

  let hideSignOut = false;
  if (!currentUser || currentUser.id === 'noauth') {
    hideSignOut = true;
  }

  return (
    <>
        <IconMenu variant="ghost" icon={<AccountIcon aria-label="menu"  size="50"/>}>
          <MenuItem onSelect={async () => { handleClick()}}>Profile</MenuItem>
          <MenuItem
            onSelect={async () => {
              handleDirectIntegrationClick()
            }}
          >
            Direct Integration
          </MenuItem>
          <hr />
          <MenuItem
            onSelect={async () => {
              await api.get('/api/signout');
              // setRedirectToSignIn(true);
              localStorage.removeItem('showDialog');
              window.location.href =
                config.sqlpadoidclogouturl +
                '?post_logout_redirect_uri=' +
                encodeURI(config.publicUrl);
            }}
            hidden={hideSignOut}
          >
            Sign out
          </MenuItem>
        </IconMenu>

        <AboutModal visible={showAbout} onClose={() => setShowAbout(false)} />
    </>
  );
}

export default React.memo(AppMenu);
