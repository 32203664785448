import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'unistore/react';
import initApp from './stores/initApp';
import useAppContext from './utilities/use-app-context';
import { api } from './utilities/fetch-json';

export interface Props {
  children: any;
  initApp: (config: Object, connections?: Array<Object>) => {};
  initialized?: boolean;
}

interface connections {
  [key: string]: any;
}

const Authenticated = (props: Props) => {
  const { children, initApp, initialized } = props;
  const { config, currentUser } = useAppContext();


  useEffect(() => {
    if (config && !initialized) {
    api.get(`/api/connections`).then((connections:connections)=>{
      if(connections.data){
        initApp(config, connections.data);
      }
      }).catch((err)=>{
      })
    }
  }, [initApp, config, initialized]);

  if (!config) {
    return null;
  }

  if (config && !currentUser) {

    const query = new URLSearchParams(window.location.search);
    const header = query.get('h');
    let path = window.location.search? window.location.pathname+window.location.search:  window.location.pathname

    return (
      <Redirect
        to={{
          pathname:
            '/signin?state=' +path+ '&h=' + header,
        }}
      />
    );
  }

  if (!initialized) {
    return null;
  }

  return children;
};

export default connect<any, any, any, any>(['initialized'], {
  initApp,
})(Authenticated);
