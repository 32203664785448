import React, {useState} from 'react'
import '../style.css'
import useAppContext from '../utilities/use-app-context';


import {
  Container
} from 'react-bootstrap';
import Modals from './Modals';

type metadataProps ={
  metaData?:any,
  isPurchased?:boolean
}

const MetaData = (props:metadataProps) => {
const { config, currentUser } = useAppContext();
const [showDialog, setShowDialog] = React.useState(false);
const metadata = (props.metaData && props.metaData.entitlements && props.metaData.entitlements.length>0)? (props.metaData.entitlements).sort((a:any, b:any) => a.entitlementName.localeCompare(b.entitlementName)):null

const downloadFile = async (type:any,file:any) => {
  if(type=="Excel"){
    return window.open(
      `${config.publicUrl}/api/downloads?type=${type}&file=${file}`,
      '_blank'
    );
  }else if(type=="PDF")
  {
    return window.open(
      `${config.publicUrl}/api/downloads?type=${type}&file=${file}`,
      '_blank'
    );
  }

  
  }
  
const showClickHandler= () => {
  setShowDialog(true);
}
const closeClickHandler= () => {
  setShowDialog(false);
}
const checkDownload = (hasSignedNDA: boolean) => {
  if (!props.isPurchased) {
    return false;
  }
  if (!hasSignedNDA && props.isPurchased) {
    return true;
  }
};
  return (
    <Container className="meta-container">
      <div className='meta-head'>
        Explore Metadata
      </div>
      <div className="meta-header-caption">These helpful materials will help you understand availability of data by
        county, coverage levels, and more.</div>

    {metadata && metadata?.sort((a: any, b: any) => a.entitlementName.localeCompare(b.entitlementName))?.map((item: any, i:any) => (
    
    <div key={i} className="container-card-meta">
        <div style={item.requiresNDA == 1 && checkDownload(item.hasSignedNDA) ? { opacity:'0.5'} : {}} className='sample-img-solution'>
          <img src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/home-page/solution-excel.svg'} />
        </div>
        <div style={item.requiresNDA == 1 && checkDownload(item.hasSignedNDA) ? { opacity:'0.5'} : {}} className='show-in-line'>
          <h5>{item.entitlementName}</h5>
          <p className='text-size'>{item.entitlementDescription}</p>
        </div>
        
   {item.entitlementType === 'Excel' &&  item.requiresNDA == 1 && checkDownload(item.hasSignedNDA)  ?  <div className="meta-download"><p onClick ={showClickHandler} className='card-download-para'>Contact Sales</p></div>   : 
   (item.downloadFile === true ? <div className="meta-download-page"  onClick={() =>downloadFile(item.entitlementType,item.defaultValue)}><img id={item.entitlementName}  className='card-download-meta' title="Download this file" src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/download.svg'} /><p></p>
   </div> : '') }
      
      </div>))} 
 <Modals  showDialog={showDialog} closeClickHandler={closeClickHandler} />
    </Container>
  ) 
}

export default MetaData;