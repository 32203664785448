import Button from '../common/Button';
import React from 'react';
import styles from '../common/Button.module.css';
import { useHistory } from 'react-router-dom';
import '../style.css'

function SolutionCatalogButton(props: any) {
  const history = useHistory();
  const handleClick = () =>{ 

      history.push('/solutioncatalog')
  };  
  const isActive=props["isActive"]==true;  
  return (
    <>
      <Button style={{minWidth:0}} className={styles.headerbutton + ' menu'+ ` ${isActive? styles.active +' menu-active': '' + 'bg-transparent text-sm-dark text-start'}`} onClick={handleClick}>
        <b>Solution Catalog</b>
      </Button>
    </>
  );
}

export default React.memo(SolutionCatalogButton);
