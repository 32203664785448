import { useEffect, useState } from 'react';
import runQueryViaBatch from './runQueryViaBatch';
import { api } from './fetch-json';
import localforage from 'localforage';
import useAppContext from './use-app-context';
function useQueryResultById(queryId: any) {
 const {config} = useAppContext()
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [queryResult, setQueryResult] = useState<any>(null);
  const [queryError, setQueryError] = useState<string>('');
  const urlParams = new URLSearchParams(window.location.search);
  const solutionpack = urlParams.get('solutionpack');

  useEffect(() => {
    const runQuery = async (queryId: any) => {
      const conectionId = config && config.defaultConnectionId
      setIsRunning(true);

      const queryJson = await api.get(`/api/queries/${queryId}${Boolean(solutionpack) ? '?solutionpack=true&cid='+conectionId :  ''}`);
      if (queryJson.error) {
        setIsRunning(false);
        setQueryError(queryJson.error);
        return;
      }
      const query = queryJson.data;

      const resultJson = await runQueryViaBatch({
        queryId,
        name: query?.name,
        chart: query?.chart,
        batchText: query?.queryText,
        connectionId: query?.connectionId,
      });
      setIsRunning(false);
      setQueryError(resultJson.error);
      setQueryResult({ ...query, ...resultJson.data });
    };

    runQuery(queryId);
  }, [queryId]);

  return [queryError, queryResult, isRunning];
}

export default useQueryResultById;
