import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'unistore/react';
import IncompleteDataNotification from '../common/IncompleteDataNotification';
import SecondsTimer from '../common/SecondsTimer';
import styles from './QueryResultHeader.module.css';
import { api } from '../utilities/fetch-json';
import ExportButton from '../common/ExportButton';
import InformationIcon from 'mdi-react/InformationIcon';
import Tooltip from '../common/Tooltip';
import IconButton from '../common/IconButton';

type OwnProps = {
  isRunning?: boolean;
  queryResult?: any;
  runQueryStartTime?: any; // TODO: PropTypes.instanceOf(Date)
  queryId?: any;
};

type Props = OwnProps & typeof QueryResultHeader.defaultProps;

function QueryResultHeader({
  isRunning,
  queryId,
  queryResult,
  runQueryStartTime,
}: Props) {

  const [queryChanged, setQueryChanged] = useState(true)
  const urlParams = new URLSearchParams(window.location.search);
  const solutionpack = urlParams.get('solutionpack');

  useEffect(() => {

    getQuery()
  }, [queryResult]);

  const getQuery = async () => {
    if (queryId && queryResult && queryResult.selectedText) {
      const { error, data } = await api.get(`/api/queries/${queryId}${Boolean(solutionpack) ? '?solutionpack=true' :  ''}`);
      if (error) {

      }
      if (queryResult.selectedText.replace(/\s+/g, '') == data?.queryText.replace(/\s+/g, '')) {
        setQueryChanged(false)
      }
      else {
        setQueryChanged(true)

      }
    }

  };
  // console.log(queryResult)

  if (isRunning || !queryResult) {
    return (
      <div className={styles.toolbar}>
        {isRunning ? (
          <span className={styles.toolbarItem}>
            Query time: <SecondsTimer startTime={runQueryStartTime} />
          </span>
        ) : null}
      </div>
    );
  }

  const rows = queryResult.rows || [];
  const links = queryResult.links || {};
  const serverSec = queryResult.durationMs / 1000;
  const rowCount = rows.length;
  const incomplete = Boolean(queryResult.incomplete);

  return (
    <div className={styles.toolbar}>
      <div className={styles.toolbarItem}>{serverSec} seconds</div>
      <div className={styles.toolbarItemInformation}>{rowCount} rows { incomplete &&
      <IconButton className={styles.tooltip} 
      title="The results are restricted to 50,000 records maximum. &#xA; Please use a standard querying tool such as Microsoft SQL Management Studio for analyzing larger datasets.">
        <InformationIcon className={styles.informationIcon} aria-label="Info" />
    </IconButton>
    }
      </div>
      <div className={styles.toolbarItem}>
        <span className={styles.iconLinkWrapper}>
          <Link
            className={styles.iconLink}
            target="_blank"
            rel="noopener noreferrer"
            to={links && links.table ? (Boolean(solutionpack) ? links.table+'?solutionpack=true' :  links.table) :''}
            // @ts-expect-error ts-migrate(2322) FIXME: Property 'disabled' does not exist on type 'Intrin... Remove this comment to see the full error message
            disabled={(!Boolean(queryId) || queryId === 'new' || queryChanged || !links.table && true) || (solutionpack && false)}
          >
            table <OpenInNewIcon style={{ marginLeft: 4 }} size={16} />
          </Link>
        </span>
      </div>
      {/* {incomplete && <IncompleteDataNotification />} */}
      <div className={styles.exportButton}>
        <ExportButton links={links} />
      </div>
    </div>
  );
}

QueryResultHeader.defaultProps = {
  isRunning: false,
};

function mapStateToProps(state: any) {
  const { isRunning, queryResult, runQueryStartTime } = state;
  return {
    isRunning,
    queryId: state.query && state.query.id,
    queryResult,
    runQueryStartTime,
  };
}

export default connect(mapStateToProps)(React.memo(QueryResultHeader));
