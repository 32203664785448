import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useAppContext from './utilities/use-app-context';
const queryString = require('query-string');



export default function HandleRedirect() {
  const history = useHistory();
  const { currentUser } = useAppContext();
  useEffect(() => {
    if ((queryString.parse(window.location.hash).state == null || queryString.parse(window.location.hash).state == '' )) {
      if(currentUser.PNUser){
      history.push('/propertynavigator');
      return
    }
      if(currentUser.data?.starting_page) {
      if(currentUser.data.starting_page === 'HomePage') history.push('/home');
      if(currentUser.data.starting_page === 'PropertyNavigator') history.push('/propertynavigator');
      if(currentUser.data.starting_page === 'SQLEditor') history.push('/queries/new');
     } else if(currentUser.data === null || !currentUser.data.starting_page || !currentUser.data){
      history.push('/home');
     }else {
      history.push('/home');
     }
      
    } else {
      history.push(queryString.parse(window.location.hash).state);
    }




  }, []);
  return null;
}



