import React from 'react';
import './SpinKitCube.css';
import useAppContext from '../utilities/use-app-context';
// http://tobiasahlin.com/spinkit/
export default function SpinKitCube() {

  const { config, currentUser } = useAppContext();

  if (!config) {
    return null;
  }



  return (
    // <div className="sk-cube-grid">
    //   <div className="sk-cube sk-cube1" />
    //   <div className="sk-cube sk-cube2" />
    //   <div className="sk-cube sk-cube3" />
    //   <div className="sk-cube sk-cube4" />
    //   <div className="sk-cube sk-cube5" />
    //   <div className="sk-cube sk-cube6" />
    //   <div className="sk-cube sk-cube7" />
    //   <div className="sk-cube sk-cube8" />
    //   <div className="sk-cube sk-cube9" />
    // </div>
    <img className="loader" src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/loading.gif'} alt="loading" />

  );
}
