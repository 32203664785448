import React, {useState, useEffect} from 'react';
import { api } from './utilities/fetch-json';
import { Container, Row, Card, Button, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import useAppContext from './utilities/use-app-context';
import CheckIcon from 'mdi-react/CheckIcon';
import message from './common/message';
import SpinKitCube from './common/SpinKitCube';

type userDataProps = {
  userData: any
}

  const PropertyApi = ({userData}:userDataProps) => {
  const {currentUser,  config } = useAppContext();
  const [apiKey, setApiKey] = useState('');
  const [showCopyMsg, setShowCopyMsg] = useState(false)
  const [showData, setShowData] = useState(false);
  const [token, setToken] = useState('');
  const history = useHistory()


  useEffect(() => {
    propertynavigator();
  }, []);

  async function propertynavigator() {
    const json = await api.get('/api/pntoken');
    if (json.error) {
      // return message.error(json.error);
    }
    if (json.data) {
      //   console.log(json.data)
      setToken(json.data);
    }
  }

  const SearchHanlder = () => {
    window.open(`${config.propertyReportLink}id=${currentUser.id}&token=${token}`,'_blank')
    // history.push('/propertyreports')
  }
  const helpCenterHanlder = () => {
   window.open('https://api.developer.attomdata.com/docs')
  }

  useEffect(() => {
    getData()
}, [])

useEffect(() => {
  const timer =   setTimeout(() => {
    setShowCopyMsg(false)
  },3000)
  
  return () => {
    clearTimeout(timer)
  }
  },[showCopyMsg])



  const getData = async () => {
    const json = await api.get(`/api/3scale-token/${currentUser.id}`);
    if (json.error) {
      setShowData(true)
        return message.error(json.error);
    }
    if (json.data) {
      setShowData(true)
        setApiKey(json.data['apiApplicationUserKey'])
        // console.log(json.data['threeScaleApplicationUserKey'])
    }
}
  
  const onCopyClick = () => {
    navigator.clipboard.writeText(apiKey)
    setShowCopyMsg(true)
}
const onCloseClick = () => {
    setShowCopyMsg(false)
}

const currentPrimaryInterest =  userData && userData.data !== null && userData.data.primary_interest === "ATTOM API"



  return (
    <div
      style={{
        width: '100%',
        margin: 'auto 0px',
        marginTop: '15px',
        marginBottom: '15px',
      }}
    >
      {!showData && <SpinKitCube />}
      {showData && <Container fluid>
        <Row  className={`property-reports ${currentPrimaryInterest && 'show-oppo'}`}>
          <Card className="propertyReport">
            <Card.Body>
              <Card.Title className="api-properties">Property Navigator</Card.Title>
              <Card.Text className="api-property interest-licence">
                Get detailed data and characteristics for over 150 million
                residential and commercial properties. Find comparables and
                conduct market analysis for valuations, appraisals, and more.
              </Card.Text>
              <Col style={{ paddingLeft: '0px' }}>
                <Button
                  style={{ margin: '0px 0px 22px 0px', minWidth: '164.17px'}}
                  className="solution-button"
                  onClick={SearchHanlder}
                >
                  Start Searching
                </Button>
              </Col>
              <Card.Img
                className="solutionPackPropertyImg"
                src={
                  process.env.PUBLIC_URL +
                  config.baseUrl +
                  '/assets/images/home-page/Group.svg'
                }
              />
            </Card.Body>
          </Card>

          <Card className="propertyReport">
            <Card.Body style={{display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column',flex: 'initial'}}>
              <div><Card.Title className="api-properties">API</Card.Title>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div>
                  <div style={{width: '80%'}} className="interest-licence">
                    Use REST API for transactional access to our extensive
                    portfolio of property data. Use this key to access ATTOM
                    API:
                  </div>
                  <div className='upper-api-button'>
                    <Button style={{ minWidth: '185px'}} className="solution-button" onClick={onCopyClick}>Copy Key</Button>
                    {showCopyMsg ? <div style={{ display: 'block'}} className="copy-msg">
                                        <div onClick={onCloseClick} className="close-btn hover">x</div>
                                        <CheckIcon className="copy-tick" /> Key Copied to Clipboard
                                    </div> : null}
                  </div>
                </div>
                <Card.Img
                  className='button-copy-image'
                  variant="top"
                  src={
                    process.env.PUBLIC_URL +
                    config.baseUrl +
                    '/assets/images/home-page/layer.svg'
                  }
                />
              </div>
              </div>

              <Card.Text className="interest-licence space-abv">
                For more information about ATTOM API, visit our
                <span className="click-solution" onClick={helpCenterHanlder}> Help Center</span>.
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
      </Container>}
    </div>
  );
};

export default PropertyApi;
