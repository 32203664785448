import Button from '../common/Button';
import React, {useState,useEffect} from 'react';
import styles from '../common/Button.module.css';
import '../style.css'
import useAppContext from '../utilities/use-app-context';
import { api } from '../utilities/fetch-json';


function PropertyReportsButton(props: any) {
  const { currentUser,config } = useAppContext();
  const [token, setToken] = useState('');

  useEffect(() => {
    propertynavigator()
},[])

  async function propertynavigator() {
    const json = await api.get('/api/pntoken');
    if (json.error) {
        // return message.error(json.error);
    }
    if (json.data) {
        //   console.log(json.data)
        setToken(json.data)
    }
}
  const handleClick = () =>{ 
    if(isIE) {
      return window.open(
        'https://cloud-help.attomdata.com/article/441-browsers',
        '_blank'
      );
    }else{
      // history.push('/propertynavigator') //DD-2915
      window.open(`${config.propertyReportLink}id=${currentUser.id}&token=${token}`,'_blank')
  
    }
  };   
 
  const isActive=props["isActive"];   
  const isIE=props["isIE"];  
  return (
    <>
      <Button style={{minWidth:0}} className={styles.headerbutton + ' menu'+ ` ${isActive? styles.active +' menu-active': '' + 'bg-transparent text-sm-dark text-start'}`} onClick={handleClick} >
        <b>Property Navigator</b>
      </Button>
    </>
  );
}

export default React.memo(PropertyReportsButton);
