import React, { useState } from 'react';
import Button from '../common/Button';
// import QueryHistoryModal from '../queryHistory/QueryHistoryModal';
import useAppContext from '../utilities/use-app-context';
import styles from '../common/Button.module.css';
import { useHistory } from 'react-router-dom';
import '../style.css'

function HistoryButton(props: any) {
  const history = useHistory();
  const handleClick = () => history.push('/documentation');

function redirectUrl()
{
  return window.open(
    'https://cloud-help.attomdata.com/',
    '_blank' 
  );
}

  const { currentUser } = useAppContext();
  //const [showQueryHistory, setShowQueryHistory] = useState(false);
  const isActive=props["isActive"]==true;  

  if (!currentUser) {
    return null;
  }

  // If an editor has no identity (e.g., logged in without authentication), query history is not available because it can not be distinguished from others'.
  if (currentUser.id === 'noauth' && currentUser.role === 'editor') return null;

  

  return (
    <div>
      <Button style={{minWidth:0,fontWeight:700}} className={styles.headerbutton + ' menu'+ ` ${isActive? styles.active +' menu-active': '' + 'bg-transparent text-sm-dark text-start'}`}  onClick={redirectUrl} >
        Documentation
      </Button>
      {/* <QueryHistoryModal
        visible={showQueryHistory}
        onClose={() => setShowQueryHistory(false)}
      /> */}
    </div>
  );
}

export default React.memo(HistoryButton);
