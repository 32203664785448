import React, { useState } from 'react';
import Button from '../common/Button';
import useAppContext from '../utilities/use-app-context';
import styles from '../common/Button.module.css';
import { useHistory } from 'react-router-dom';
import '../style.css'
function ForeClosureButton(props: any) {
  const history = useHistory();
  const handleClick = () => history.push('/visualize');
  const { currentUser } = useAppContext();
  const isActive=props["isActive"]==true;  

  if (!currentUser) {
    return null;
  }

  return (
    <div>
      <Button  className={styles.subheaderbutton + ' menu'+ ` ${isActive? styles.active +' sub-menu-active': ''}`} onClick={handleClick} >
      Foreclosure Report
      </Button>
    </div>
  );
}

export default React.memo(ForeClosureButton);
