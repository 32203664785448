import React, { useState, useEffect } from 'react';
import '../style.css';
import { api } from '../utilities/fetch-json';
import message from '../common/message';
import useAppContext from '../utilities/use-app-context';
import CheckIcon from 'mdi-react/CheckIcon';


function ApiToken() {

    const { currentUser, config } = useAppContext();

    const [apiKey, setApiKey] = useState('');
    const [showCopyMsg, setShowCopyMsg] = useState(false);

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        const json = await api.get(`/api/3scale-token/${currentUser.id}`);
        if (json.error) {
            return message.error(json.error);
        }
        if (json.data) {
            setApiKey(json.data['apiApplicationUserKey'])
            // console.log(json.data['threeScaleApplicationUserKey'])
        }
    }


    const onCopyClick = () => {
        navigator.clipboard.writeText(apiKey)
        setShowCopyMsg(true)
    }
    const onCloseClick = () => {
        setShowCopyMsg(false)
    }

    if(!apiKey){
        return (<></>)
    }


    return (
        <>
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-border">
                                <div className="direct-heading"><span>ATTOM API</span></div>
                                <br />
                                <p>Use this key to access ATTOM API:</p>
                                <div>
                                    <span >{apiKey}</span>
                                    <button className="copy-btn" onClick={onCopyClick}>Copy Key</button>
                                    {showCopyMsg ? <div className="copy-msg">
                                        <div onClick={onCloseClick} className="close-btn hover">x</div>
                                        <CheckIcon className="copy-tick" /> Key Copied to Clipboard
                                    </div> : null}
                                </div>
                                <hr />
                                <p> For more information about ATTOM API, visit our <a style={{ color: '#EB4734' }} href="https://api.developer.attomdata.com/docs" target="_blank">help center</a> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ApiToken;
