import { useEffect } from "react";
import useAppContext from '../utilities/use-app-context';
import { api } from '../utilities/fetch-json';


export default function RedirectPropertyNavigator() {
  const { config, currentUser } = useAppContext();
  useEffect(() => {
    propertynavigator()
},[])

  async function propertynavigator() {
    const json = await api.get('/api/pntoken');
    if (json.error) {
    }
    if (json.data) {
        window.location.replace(`${config.propertyReportLink}id=${currentUser.id}&token=${json.data}`)
    
    }
}
  return null;
}



