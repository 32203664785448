import React from 'react';
import useAppContext from '../utilities/use-app-context';
import '../style.css';
const SearchCatalog = (props: any) => {
  const { config, currentUser } = useAppContext();


  return (
    <>
      <input
        className="search-catalog"
        type="search"
        placeholder="Search"
        onChange={props.handleChange}
      />
      <img
        className="search-img"
        src={
          process.env.PUBLIC_URL +
          config.baseUrl +
          '/assets/images/home-page/search.svg'
        }
        alt="search"
      />
    </>
  );
};

export default SearchCatalog;
