import React, {useEffect} from 'react';
import useAppContext from '../utilities/use-app-context';
import PropertyReportsLink from './PropertyReportsLink';
import '../style.css';
import CheckThirdPartyCookie from '../utilities/CheckThirdPartyCookie';
// import useGaTracker from '../common/useGaTracker'
import useHeapTracker from '../common/useHeapTracker'
import MainFooter from '../app-footer/MainFooter';


function PropertyReports () {
    const { config } = useAppContext();
    
    useEffect(() => {
      document.title = 'ATTOM Cloud';
    }, []);
  
  return (
    <>
      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CheckThirdPartyCookie/>    
        <PropertyReportsLink/>
  </div>
  <MainFooter/>
</>
      );
  }
  
  export default PropertyReports;