import React from 'react'
import '../style.css'
import { Container } from 'react-bootstrap';
import useAppContext from '../utilities/use-app-context';
import { Item } from '../common/MultiSelectHelpers';
import Modals from './Modals';

type sampleanalyticsProps ={
    sampleAnalytics?:any,
    solutionDetails?:any,
    isPurchased?:any
  }
const SampleAnalytics = (props:sampleanalyticsProps) => {

    const { config, currentUser } = useAppContext();
    const [showDialog, setShowDialog] = React.useState(false);
    const [heapData, setHeapData] = React.useState<any>({});
    const sampleAnalyticdata = (props.sampleAnalytics && props.sampleAnalytics.entitlements && props.sampleAnalytics.entitlements.length>0)?props.sampleAnalytics.entitlements.sort((a:any, b:any) => a.entitlementName.localeCompare(b.entitlementName)):null
    const downloadFile = async (type:any,file:any,from:any) => {
      if((type=="Excel" || type=="PowerBI" || type== 'Tableau' || type == 'Jupyter' ) && from=='btn'){
          return window.open(
            `${config.publicUrl}/api/downloads?type=${type}&file=${file}`,
            '_blank'
          );
        }else if(type=="PDF" && from=='card')
        {
          return window.open(
            `${config.publicUrl}/api/downloads?type=${type}&file=${file}`,
            '_blank'
          );
        }
      

        }
        const showClickHandler= () => {
          setShowDialog(true);
        }
        const closeClickHandler= () => {
          setShowDialog(false);
        }
        
        const viewFile = (link:any) => {
            return window.open(
              link,
              '_blank'
            );
        };

        const checkDownload = (hasSignedNDA: boolean) => {
          if (!props.isPurchased) {
            return false;
          }
          if (!hasSignedNDA && props.isPurchased) {
            return true;
          }
        };

    return (
        <Container className="sample-container" >
            <div className='analytics-head'>
                Sample Analytics
            </div>
            <div className="header-caption">Here are a few examples of analyses performed using the {props.solutionDetails?props.solutionDetails.subscriptionName:''} solution pack.</div>

        {sampleAnalyticdata && sampleAnalyticdata?.sort((a: any, b: any) => a.entitlementName.localeCompare(b.entitlementName))?.map((item: any, i:any) => ( <div key={i} className="sample-list">
                <div style={item.requiresNDA == 1  && checkDownload(item.hasSignedNDA) ? { opacity:'0.5'} : {}} className='sample-img-solution'>
                {  item.entitlementType === 'Excel' &&   <img src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/home-page/solution-excel.svg'} /> }
                {  item.entitlementType === 'Jupyter' &&   <img src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/home-page/jupyter.svg'} /> }
                {  item.entitlementType === 'PowerBI' &&   <img src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/home-page/power-bi.svg'} /> }
                {  item.entitlementType === 'Tableau' &&   <img src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/home-page/tableau.svg'} /> }
                {  !(item.entitlementType === 'Excel' ||  item.entitlementType === 'PowerBI' ||  item.entitlementType === 'Tableau' ||  item.entitlementType === 'Jupyter' ) &&   <img style={{visibility:'hidden'}} src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/home-page/solution-excel.svg'} /> }
                </div>
                <div style={item.requiresNDA == 1 && checkDownload(item.hasSignedNDA) ? { opacity:'0.5'} : {}} className='show-in-line'>
                    <h5> {item.entitlementName}</h5>
                    <p className='text-size'>{item.entitlementDescription}</p>
                </div>

                {  item.entitlementType === 'Excel' && item.requiresNDA == 1 && checkDownload(item.hasSignedNDA) ?  <div className="meta-download"><p style={{ width:'max-content'}} onClick ={showClickHandler} className='card-download-para'>Contact Sales</p></div>  : 
                (item.downloadFile === true ? <div className='view-download-button'>
                <img onClick={() => viewFile(item.dataElementLink)}  className={`view-download-meta ${item.dataElementLink === null && 'display-none-view'}`} title="View this file" src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/view.svg'} /> 
                <div className="meta-download"  onClick={() =>downloadFile(item.entitlementType,item.defaultValue,"btn")}><img id={item.entitlementName} className='card-download-meta' title='Download this file' src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/download.svg'} /></div></div> : '') }
            </div>))}
            <Modals  showDialog={showDialog} closeClickHandler={closeClickHandler} />
        </Container>
    )
}

export default SampleAnalytics;