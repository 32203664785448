
import React from 'react'
import CloseIcon from 'mdi-react/CloseIcon';
import IconButton from '../common/IconButton';
import styles from './CloudObjectModel.module.css'
import useAppContext from '../utilities/use-app-context';
import { DialogContent, DialogOverlay } from '@reach/dialog'
type ObjectProps = {
    cloudObjects:any
    closeModalHanlder:() => void
    solutionDetails:any
    showModal:boolean
}
const CloudObjectsModel = (props:ObjectProps) => {
    const overlayRef = React.useRef(null);
    const contentRef = React.useRef(null);
    const { config, currentUser } = useAppContext();
    const {cloudObjects,closeModalHanlder,solutionDetails,showModal} = props


  return (
    <div> <DialogOverlay
    ref={overlayRef}
    style={{ background: 'rgb(0 0 0/ 60%)' }}
    isOpen={showModal}
    onClick={closeModalHanlder}
  // onDismiss={close}
  >
    <DialogContent
      aria-label="Announcement"
      ref={contentRef}
      style={{
        boxShadow: '0px 10px 50px hsla(0, 0%, 0%, 0.33)',
        width: '60vw',
      }}
      className="purchaseDialog"
    >
      <img onClick={closeModalHanlder} className={styles.closeimg} src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/home-page/closeIconButton.svg'}/>
      <div className={styles.boxModal}>
        <div className={styles.modalHeading}>
      The {solutionDetails?solutionDetails.subscriptionName:''} Solution Pack includes direct access to the following SQL Objects:
      </div>
    {cloudObjects && cloudObjects.entitlements?.map((item:any,index:any) => (
 <div className={styles.DataValue} key={index}>
 <div className={styles.entiName}>{item.defaultValue}</div>
 <div className={styles.entiDesc}>{item.entitlementDescription}</div>
</div>
    ))}  
      
      </div>

    </DialogContent>
  </DialogOverlay>   </div>
  )
}

export default CloudObjectsModel