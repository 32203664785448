import React, { useEffect, useState, useRef } from 'react';
import useAppContext from '../utilities/use-app-context';
import '../style.css';
import CheckThirdPartyCookie from '../utilities/CheckThirdPartyCookie';
// import useGaTracker from '../common/useGaTracker';
import useHeapTracker from '../common/useHeapTracker';
import { api } from '../utilities/fetch-json';
import { Container, Card, Button } from 'react-bootstrap';
import MainFooter from '../app-footer/MainFooter';
import FreeTrial from './FreeTrail';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import SpinKitCube from '../common/SpinKitCube';
import SearchCatalog from './SearchCatalog';
import Multiselect from 'multiselect-react-dropdown';
import SolutionVedioModel from './SolutionVedioModel';
import queryString from 'query-string';

type Props = {
    favroritesId: string;
};

function SolutionCatlog(props: Props) {
    const { config, currentUser } = useAppContext();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [defaultSolution, setDefaultSolution] = useState([]); //use
    const [industries, setIndustries] = useState([]); //use
    const [searchField, setSearchField] = useState(''); //use
    const [selectedData, setSelectedData] = useState([]); //use
    const [dataElemenets, setDataElelments] = useState([]);
    const [selectedQueryParams, setSelectedQueryParams] = useState<any[]>([]); //use
    const [selectedSolutionPackData, setSelectedSolutionPackData] = useState([]); //use
    const [allSettled,setAllSettled] = useState(false)
    const [newData, setNewData] = useState([]);
    const [data, setData] = useState<any[]>([]);
    const [subscription, setSubscription] = useState([]);
    const [result, setResult] = useState('');
    const [search, setSearch] = useState('');
    const [solutionPack, setSolutionPack] = useState(null);
    const [dataset, setDataset] = useState(null);
    const [watchVideo, setWatchVideo] = useState(false)
    const history = useHistory();
    const multiselectRef = useRef<any>(null);
    const singleselectRef = useRef<any>(null);
    let queryParamsFlag :any;
    // useGaTracker();
    useHeapTracker();

    useEffect(() => {
        document.title = 'Solution Catalog';
        if(currentUser.isActiveSolution){
        getSubscription()
        getIndustries()
        } else {
            setDataLoaded(false)
        }
    }, []);
    const getDefaultSolutionPack = async () => {
        const json = await api.get(`/api/get-default-solution-pack`);
        if (json.data) {
            setDefaultSolution(
                json.data[0].sort((a: any, b: any) =>
                    a.subscription_name.localeCompare(b.subscription_name)
                )
            );
            setNewData(json.data[0].sort((a: any, b: any) =>
            a.subscription_name.localeCompare(b.subscription_name)
        ))
        setDataLoaded(true);
            setDataElelments(
                json.data[1]
            );
            if(queryParamsFlag == 1){
                let industryParam: any = queryString.parse(window.location.search).industry;
                if(industryParam){
                    industryParam = industryParam.split(" ").map((name:any) => name.charAt(0).toUpperCase() + name.slice(1)).join(" ");
                let filteredData: any = []
                SelectedDataHandler([{industry: industryParam}], [])
                }
            }
            }
        if (json.error) {
            // return message.error(json.error);
        }
    };
  let subscriptions:any= subscription && subscription.length > 0 && subscription 

    const isPurchasedSubscription =(data:any) => { 
        for(let i = 0 ; i < subscriptions.length ; i++){
            if(data === subscriptions[i].subscriptionCode) {
                return true
            } 
    }
}
    const getSubscription = async () => {
        const json = await api.get(`/api/purchased-subscription?uid=${currentUser.id}`);
        getDefaultSolutionPack();

        if (json.data) {
          // console.log('Entitlements', json.data);
          const results = json.data.filter(
            (data: any) => data.clientSubscriptionCode !== null
          );
          setSubscription(results);
        //   setDataLoaded(true)
        }
        if (json.error) {
          // return message.error(json.error);
        }
      };

    const handleClose = () => setWatchVideo(false);
  const handleShow = (result:any) => {
    setResult(result)
    setWatchVideo(true);
  }

    const getIndustries = async () => {
        let obj: any
        const json = await api.get(`/api/get-industries`);
        if (json.data) {
            setIndustries(json.data)
        }

        let industryParam: any = queryString.parse(window.location.search).industry;
        if(industryParam){
            industryParam = industryParam.split(" ").map((name:any) => name.charAt(0).toUpperCase() + name.slice(1)).join(" ");
            const found = json.data.some((r: any) => r.industry === industryParam);
            
            if (found) {
                setSelectedQueryParams([{ industry:industryParam }]);
                queryParamsFlag=1;
            }
        }
        if (json.error) {
            // return message.error(json.error);
        }
    };

    useEffect(() => {
        fetchAsyncData()
    },[defaultSolution,subscription,industries,allSettled])

    const fetchAsyncData = async () => {
        const res = await  Promise.allSettled([getSubscription,getDefaultSolutionPack,getIndustries]);
        if(res) setAllSettled(true)
      };

 
  
    const SelectedDataHandler = (selectedList: any, selectedItem: any) => {
        const allItems = selectedList.map((item: any) => {
            return item?.industry;
        })

        setSelectedData(allItems)
    };
    const removeDataHandler = (selectedList: any, selectedItem: any) => {
        const allItems = selectedList.map((item: any) => {
            return item?.industry;
        })

        setSelectedData(allItems)
    };
    const SelectedSolutionPackDataHandler = (selectedList: any, selectedItem: any) => {

        setSelectedSolutionPackData(selectedList)
    };
    const removeSolutionPackDataHandler = (selectedList: any, selectedItem: any) => {

        setSelectedSolutionPackData(selectedList)
    };
    const solutionCheckBox = (e:any) => {
        if(e.target.checked){
            setSolutionPack(e.target.value)
        } else if(!e.target.checked) {
            setSolutionPack(null)
        }
      }
      const dataSetCheckBox = (e:any) => {
        if(e.target.checked){
            setDataset(e.target.value)
        } else if(!e.target.checked) {
            setDataset(null)
        }
      }

    React.useEffect(() => {
        var outputdata: any = [];
        const updatedData: any = defaultSolution && defaultSolution?.filter((defData: any) => {
            const name = defData?.subscription_name.toLowerCase();
            const desc = defData && defData.subscription_desc? defData?.subscription_desc.toLowerCase():'';
            const dataElement = defData && defData.entitlement_name;
            let dataDefault;
            // if(defData && defData.industry){
                dataDefault = defData && defData.industry && defData.industry.split(';').some((ddata: never) => {
                    return selectedData.includes(ddata) && name.includes(searchField.toLowerCase())  || (selectedData.includes(ddata) && desc.includes(searchField.toLowerCase()))
                })
                if(selectedData.length<1 && (!searchField || searchField==='')){
                    dataDefault = defaultSolution;
                }
                if(selectedData.length<1 && (searchField && searchField!=='')){
                    dataDefault = defData && defData.industry && defData.industry.split(';').some((ddata: never) => {
                        return (name.includes(searchField.toLowerCase()) || desc.includes(searchField.toLowerCase()))
                    })
                }
                if(selectedData.length === 0 && (searchField && searchField!=='')){
                    dataDefault = name.includes(searchField.toLowerCase()) || desc.includes(searchField.toLowerCase())
                }
            // }else{
            //     dataDefault = name.includes(searchField)        
            // }
            return dataDefault
        })
        if (selectedSolutionPackData && selectedSolutionPackData.length > 0) {
            const filteredDataElementsName = dataElemenets.filter((elem: any) => {
                return selectedSolutionPackData?.every((item: any) => {
                    return elem.entitlement_name === item.entitlement_name
                }
                )
            })
            const filteredDataElementsbycode = filteredDataElementsName && filteredDataElementsName?.filter((item1: any) => {
                return updatedData && updatedData?.some((dataCode: any) => {
                    return item1.subscription_code === dataCode.subscription_code 
                })
            })
            const filteredDataElementsbycodeImg = defaultSolution && defaultSolution?.filter((item1: any) => {
                return filteredDataElementsbycode && filteredDataElementsbycode?.some((dataCode: any) => {
                    return item1.subscription_code === dataCode.subscription_code 
                })
            })  

            filteredDataElementsbycode && setNewData(filteredDataElementsbycodeImg)
            outputdata = filteredDataElementsbycodeImg;
        } else {
            const filteredData = updatedData;
            filteredData && setNewData(filteredData);
            outputdata = filteredData;
        }
        if(solutionPack && dataset) {
            let filteredData;
             filteredData = outputdata.filter((item:any) => {
                return  (item.type.toLowerCase() === "Solution Pack".toLowerCase() || item.type.toLowerCase() === "Dataset".toLowerCase())
            } )
            setNewData(filteredData)
        }
        if(solutionPack && !dataset) {
            let filteredDatas;
             filteredDatas = outputdata.filter((item:any) => {
                return item.type.toLowerCase() === "Solution Pack".toLowerCase()
            } )
            setNewData(filteredDatas)
        }
        if(!solutionPack && dataset) {
            let filteredData;
             filteredData = outputdata.filter((item:any) => {
                return  item.type.toLowerCase() === "Dataset".toLowerCase()
            } )
            setNewData(filteredData)
        }
        
    }, [selectedData, searchField, selectedSolutionPackData, solutionPack, dataset])
    const tryClick = (solutionId: any) => {
        if (solutionId) {
            history.push(`/solutionpack/${solutionId}`);
        } else {
        }
    };

    const handleChange = (e: any) => {
        setSearchField(e.target.value);
    };

    React.useEffect(() => {
        const dataSelected =
            selectedData &&
            Object.values(selectedData)
                .flat(3)
                .map((value) => value);
        setData(dataSelected);
    }, [selectedData])



    React.useEffect(() => {
        searchValue(search)
    }, [])

    function searchValue(value: any) {
        setSearch(value)
    }

    const sortedIndustries = industries.sort((a: any, b: any) => a.industry.localeCompare(b.industry))
    const filterIndustries = sortedIndustries && sortedIndustries.filter((indust: any) => {
        return (
            indust.industry
                .toLowerCase()
                .includes(search.toLowerCase())
        )
    })

    const resetSelectField = () => {
        if (multiselectRef) {
            multiselectRef?.current?.resetSelectedValues();
            setSelectedData([]);
        }
        
    };
    const resetSingleSelectField = () => {
    if (singleselectRef) {
        singleselectRef?.current?.resetSelectedValues();
        setSelectedSolutionPackData([]);
    }
    }


    const ids = dataElemenets.map((o: any) => o.entitlement_name)
    const filtered = dataElemenets.filter(({ entitlement_name }, index) => !ids.includes(entitlement_name, index + 1)).sort((a: any, b: any) => a.entitlement_name.localeCompare(b.entitlement_name));
    return (
        <div
            style={{
                height: '100vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <CheckThirdPartyCookie />
            {!dataLoaded ? (
                <div className="loading-div">
                    <SpinKitCube />
                </div>
            ) : (
                ''
            )}
            {allSettled && dataLoaded && (
                <div> 
                    <Container style={{ maxWidth: '86%' }}>
                        <div style={{ borderBottom: 'none' }} className="query-head">
                            Solution Catalog
                        </div>
                        <div className="header-caption">
                            Your 30 Day Free Trial includes access any Solution Pack (limited
                            to 5 counties of data). For expanded coverage, questions about
                            pricing, or to purchase a license, contact Sales at 800-659-2877.
                        </div>
                        <div className="search-select">
                            <div>
                                <SearchCatalog
                                    handleChange={handleChange}
                                    searchField={searchField}
                                />
                            </div>
                            <div className="industries_select">
                            <Multiselect
                                className="select-multi"
                                displayValue="industry"
                                showArrow={data && selectedData.length > 0 || data.length > 0 ? true : false}
                                customArrow={<><img style={{ width: '15px', cursor: 'pointer' }} src={data.length > 0 || search.length > 0 ? process.env.PUBLIC_URL + config.baseUrl + '/assets/images/cancel.png' : ""} onClick={resetSelectField} /></>}
                                // onKeyPressFn={handleChangeSelect}
                                onRemove={removeDataHandler}
                                // onSearch={searchValue}
                                selectedValues={selectedQueryParams}
                                onSelect={SelectedDataHandler}
                                ref={multiselectRef}
                                placeholder="All Industries"
                                hidePlaceholder={defaultSolution.length > 0 ? true : false}
                                options={filterIndustries}
                                avoidHighlightFirstOption={true}
                                showCheckbox
                                style={{
                                    chips: {
                                        background: '#eb4734',
                                        color: '#ffffff',
                                    },
                                    multiselectContainer: {
                                        color: '#424242',
                                    },
                                    searchBox: {
                                        border: 'none',
                                        borderBottom: '1px solid blue',
                                        borderRadius: '0px',
                                    },
                                    optionContainer : {
                                        overflow: 'hidden',
                                    },
                                }}
                            />
                            </div>
                            {/* <div className="data-Element-select">
                             <Multiselect
                                className="select-multi"
                                
                                displayValue="entitlement_name"
                                // showArrow={true}
                                customArrow={<><img style={{ width: '15px', cursor: 'pointer' }} src={selectedSolutionPackData.length > 0 ? process.env.PUBLIC_URL + config.baseUrl + '/assets/images/cancel.png' : ""} onClick={resetSingleSelectField} /></>}
                                // onKeyPressFn={handleChangeSelect}
                                onRemove={removeSolutionPackDataHandler}
                                // onSearch={searchValue}
                                onSelect={SelectedSolutionPackDataHandler}
                                ref={singleselectRef}
                                placeholder="Data Element"
                                hidePlaceholder={defaultSolution.length > 0 ? true : false}
                                options={filtered}
                                avoidHighlightFirstOption={true}
                                // showCheckbox
                                style={{
                                    chips: {
                                        background: '#eb4734',
                                        color: '#ffffff',
                                    },
                                    multiselectContainer: {
                                        color: '#424242',
                                    },
                                    searchBox: {
                                        border: 'none',
                                        borderBottom: '1px solid blue',
                                        borderRadius: '0px',
                                    },
                                    optionContainer : {
                                        height: '316px',
                                        overflowY: 'scroll',
                                        overflowX: 'hidden',
                                    },
                                }}
                                singleSelect
                            /> */}
                            {/* </div> */}
                            <div>
                            <div className='checkbox-main'>
      <div className='wrap-check'>
        <input onClick={solutionCheckBox}  type="checkbox" className="checkbox-sc" id="Solution Pack" name="Solution Pack" value="Solution Pack" />
        <div style={{paddingLeft:'13px'}}>
        <label className='checkbox-label' htmlFor="Solution Pack"> Solution Packs</label>
        <p className='checkbox-text'>Convenient dataset bundles for specific business uses</p>
        </div>
      </div>
      <div className='wrap-check'>
        <input onClick={dataSetCheckBox} type="checkbox" className="checkbox-sc" id="dataset" name="dataset" value="Dataset" />
        <div style={{paddingLeft:'13px'}}>
        <label className='checkbox-label' htmlFor="dataset">Datasets</label>
        <p className='checkbox-text'>Available independently or as add-ons to Solution Packs</p>
        </div>
      </div>
    </div>
                            </div>
                        </div>
                    
                        <SolutionVedioModel handleClose={handleClose} show={watchVideo} subscriptionVideo={result}/>
                        <Row lg={5}>
                            {newData.length > 0
                                ? newData.map((result: any, i) => {
                                    return (
                                      <Card
                                        key={i}
                                        style={{
                                          minWidth: '255px',
                                          maxWidth: '255px',
                                          padding: '20px',
                                          paddingBottom: '5px',
                                        }}
                                        className="card-catalog-style"
                                      >
                                        {result.type !== null && <div className={result.type === "Solution Pack" ? 'solution-type' :"dataset-type"}><p className='solution-type-text'>{result.type}</p></div>}
                                        {result.subscription_icon ? (
                                          <Card.Img
                                            style={{
                                              width: '155px !important',
                                              height: '100px',
                                            }}
                                            draggable={false}
                                            className="dive-card-img"
                                            src={`data:image/svg+xml;base64,${result.subscription_icon}`}
                                            variant="bottom"
                                            alt="Image not found"
                                          />
                                        ) : (
                                          <Card.Img
                                            style={{
                                              width: '155px !important',
                                              height: '100px',
                                            }}
                                            draggable={false}
                                            className="dive-card-img"
                                            src={
                                              process.env.PUBLIC_URL +
                                              config.baseUrl +
                                              '/assets/images/SP-Default.svg'
                                            }
                                            variant="bottom"
                                            alt="Image not found"
                                          />
                                        )}
                                        <Card.Body>
                                          <Card.Title className="card-title">
                                            {result.subscription_name}
                                          </Card.Title>
                                          <Card.Text className="card-catalog-text">
                                            {result.subscription_desc}
                                          </Card.Text>
                                        </Card.Body>
                                        {isPurchasedSubscription(result.subscription_code) ? (
                                          <div className="isPurchasedCatalog">
                                            <button
                                              className="try-button"
                                              onClick={() =>
                                                tryClick(
                                                  result.subscription_code
                                                )
                                              }
                                            >
                                              Go
                                            </button>
                                            <>
                                            <img
                                            className="solutionTicked"
                                            src={
                                              process.env.PUBLIC_URL +
                                              config.baseUrl +
                                              '/assets/images/solutioncatTick.svg'
                                            }
                                            alt="Image not found" />
                                            <p className='solutionLicensed'>Licenced</p>
                                            </>
                                          </div>
                                        ) : (
                                          <div className="isPurchasedCatalog">
                                            <button
                                              className="try-button"
                                              onClick={() =>
                                                tryClick(
                                                  result.subscription_code
                                                )
                                              }
                                            >
                                              Try
                                            </button>
                                          {result.videoLink?  <p className='solutionWatchVedio' onClick={() => {handleShow(result.videoLink)}}>Watch Video</p>:''} 
                                          </div>
                                        )}
                                      </Card>
                                    );
                                })
                                : null}
                            {newData.length === 0 && (
                                <p className="no-search">No search results found</p>
                            )}
                        </Row>
                    </Container>
                   
                    <FreeTrial />
                    <MainFooter />{' '}
                </div>
            )}
        </div>
    );
}

export default SolutionCatlog;
