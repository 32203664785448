import React, { useState } from 'react';
import useAppContext from '../utilities/use-app-context';
import { useHistory } from 'react-router-dom';


function MainFooter() {

  const history = useHistory();
  const { config } = useAppContext();
  const handleClick = () => history.push('/home');
  const userProfile = () => history.push('/home');


  const currentYear = () => {
    return new Date().getFullYear()
  };

  const termsofUseClick = () => {
    // history.push('/termsofuse');
    window.open("/termsofuse", "_blank");
  };


  return (
    <div style={{
      width: '100%',
      color: '#fff',
      backgroundColor: '#1d1d1d',
    }} className="main-footer">

      <div className="footer-left-content">
        <div>
          <img src={process.env.PUBLIC_URL + config.baseUrl + '/assets/images/Logo_footer.svg'} />
        </div>
        <div className="footer-learn-more">
          ATTOM Data Solutions is the premier provider of real estate and property data. 
<a style={{ color: '#EB4734',fontWeight:'bold' }} href="https://www.attomdata.com/data/" target="_blank"> Learn More</a>
<br/>Questions? Contact Sales at 800-659-2877
        </div>
      </div>
      <div className="footer-copy">&copy; 1996-{currentYear()} ATTOM Data Solutions - All Rights Reserved | <a onClick={termsofUseClick}>Terms of Use</a> | <a className="no-anchor" href="https://www.attomdata.com/privacy/" target="_blank">Privacy</a> </div>



    </div>
  );
}

export default React.memo(MainFooter);
