import React, { useState, useEffect } from 'react';
import { api } from './utilities/fetch-json';
import './style.css';
import { Container, Button, Row, Col } from 'react-bootstrap';
import SpinKitCube from './common/SpinKitCube'
import { useHistory } from 'react-router-dom';
import useAppContext from './utilities/use-app-context';
import TrySolutionPack from './TrySolutionPack';
const SolutionPack = (props: any) => {
  const { config, currentUser } = useAppContext();
  const [solutionPack, setSolutionPack] = useState<any>(false);
  const [subscription, setSubscription] = useState([]);
  const [defaultSolution, setDefaultSolution] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    document.title = 'ATTOM Cloud';
    if(currentUser.isActiveSolution){
    getDefaultSolutionPack(signal);
    } else {
      setDataLoaded(false)
    }
    getSubscription(signal);
    return () => {
      abortController.abort();
    };
  }, []);

  const getSubscription = async (signal: any) => {
    const json = await api.get(`/api/subscription?uid=${currentUser.id}`, signal);
    if (json.data) {
      // console.log('Entitlements', json.data);
      const results = json?.data?.filter(
        (data: any) => data.clientSubscriptionCode !== null
      );
      if (results.length > 0) {
        setSolutionPack(true);
      }
      setSubscription(
        results.sort((a: any, b: any) =>
          a.subscriptionName.localeCompare(b.subscriptionName)
        )
      );
      setDataLoaded(true)
    }
    if (json.error) {
      // return message.error(json.error);
    }
  };
  const getDefaultSolutionPack = async (signal: any) => {
    const json = await api.get(`/api/get-default-solution-pack`, signal);
    if (json.data) {
      setDefaultSolution(
        json.data[0].sort((a: any, b: any) =>
          a.subscription_name.localeCompare(b.subscription_name)
        )
      );
        setDataLoaded(true);
    }
    if (json.error) {
      // return message.error(json.error);
    }
  };
  const seeFullCataLogHandler = () => {
    history.push('/solutioncatalog');
  };
  const sqlHandler = () => {
    history.push('/queries/new');
  };
  const directIntegrationHandler = () => {
    history.push('/directintegration');
  };
  const solutionClick = (subscriptionCode: any) => {
    history.push(`/solutionpack/${subscriptionCode}`);
  };
  function getMultipleRandom(arr: any, num: number) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());

    return shuffled.slice(0, num);
  }
  const tryClick = (solutionId: any) => {
    if (solutionId) {
      history.push(`/solutionpack/${solutionId}`);
    } else {
    }
  };

  const filterByReference = (arr1:any, arr2:any) => {
    let res = [];
    res = arr1.filter((el:any) => {
       return !arr2.find((element:any) => {
          return element.subscriptionName === el.subscription_name;
       });
    });
    return res;
 }

const filteredDataNot = filterByReference(defaultSolution,subscription)

  const fouritems = getMultipleRandom(defaultSolution, 4);
  const twoitems = getMultipleRandom(filteredDataNot, 2);


  return (
    <>
    {!dataLoaded && <SpinKitCube  />}
     {dataLoaded && <div style={{ width: '100%', margin: 'auto' }}>
        <Container className="container-solutionpack">
          <div>
            <div>
              <h1 className="heading-solution">Solution Packs</h1>
            </div>
            <div>
              <p className="interest-licence">
                Get immediate access to data, queries, analytics and support to
                solve specific business needs.
              </p>
            </div>

            <Row className="row-Specification">
              {!solutionPack && <TrySolutionPack items={fouritems} />}
              {solutionPack && (
                <>
                  <Col style={{ flexBasis: 'auto', marginTop: '20px' }}>
                    <h3 className="solution-heading">Your Solution Packs</h3>
                    <div className="scrollable">
                      {subscription && subscription.length > 0
                        ? subscription.map((result: any, i) => {
                            return (
                              <div
                                key={i + 1}
                                className="solutionPack-column"
                                onClick={() =>
                                  solutionClick(result.subscriptionCode)
                                }
                              >
                                <div key={i + 2} className="solutionPack-row">
                                <div style={{maxWidth: '50px', maxHeight: '50px'}}>
                                  <img
                                  className="solution-pack-img"
                                    src={
                                      result.solutionPackImageName
                                        ? `data:image/svg+xml;base64,${result.solutionPackImageName}`
                                        : process.env.PUBLIC_URL +
                                          config.baseUrl +
                                          '/assets/images/SP-Default.svg'
                                    }
                                    alt="Image not found"
                                  />
                                  </div>
                                  <p className="interest-licence solution-center">
                                    {result.subscriptionName}
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </Col>
                  <Col style={{ flexBasis: 'auto', marginTop: '20px' }}>
                    <div>
                      <h3 className="solution-heading ">
                        More Solution Packs to Try
                      </h3>
                      <div>
                        {twoitems && twoitems.length > 0
                          ? twoitems.map((item: any, i) => {
                              return (
                                <div
                                  key={i + 1}
                                  className="solutionPack-column"
                                >
                                  <div
                                    key={i + 2}
                                    className="solutionPack-row-try"
                                  >
                                    <div style={{maxWidth: '50px', maxHeight: '50px'}}>
                                      <img
                                       className="solution-pack-img"
                                      src={
                                        item.subscription_icon
                                          ? `data:image/svg+xml;base64,${item.subscription_icon}`
                                          : process.env.PUBLIC_URL +
                                            config.baseUrl +
                                            '/assets/images/SP-Default.svg'
                                      }
                                    />
                                    </div>
                                    <p className="interest-licence interest-licence-try solution-center">
                                      {item.subscription_name}
                                    </p>
                                    <p
                                      className="button-tryClick"
                                      onClick={() =>
                                        tryClick(item.subscription_code)
                                      }
                                    >
                                      Try
                                    </p>
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </div>

                      <Button
                        style={{ minWidth: '200px' }}
                        className="solution-button"
                        onClick={seeFullCataLogHandler}
                      >
                        See the Full Catalog
                      </Button>
                    </div>
                  </Col>
                </>
              )}
              <Col
                style={{ flexBasis: 'inherit', marginTop: '20px' }}
                className={!solutionPack ? ' sql-editor' : 'col-subs'}
              >
                <div>
                  <h3 className="solution-heading">SQL Editor</h3>
                  <p className="rows interest-licence">
                    SQL Editor Access pre-made queries or write your own with
                    our lightweight
                    <span className="click-solution" onClick={sqlHandler}>
                      {' '}
                      SQL Editor
                    </span>
                    .
                  </p>
                  <h3 className="solution-heading">Direct Integration</h3>
                  <p className="rows interest-licence">
                    Prefer to use your own SQL Editor? Get what you need to
                    integrate
                    <span
                      className="click-solution"
                      onClick={directIntegrationHandler}
                    >
                      {' '}
                      here
                    </span>
                    .
                  </p>
                </div>
              </Col>
            </Row>
            {/* </Row> */}
          </div>
        </Container>
      </div> }
    </>
  );
};

export default SolutionPack;
