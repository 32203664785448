import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './css/reset.css';
import '@reach/dialog/styles.css';
import '@reach/menu-button/styles.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { SWRConfig } from 'swr';
import { Provider } from 'unistore/react';
import { MessageDisplayer } from './common/message';
import './css/index.css';
import './css/react-split-pane.css';
import './css/vendorOverrides.css';
import Routes from './Routes';
import unistoreStore from './stores/unistoreStore';
import swrFetcher from './utilities/swr-fetcher';
import { KeyStateProvider } from './stores/key-state';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './common/ApplicationInsightsService';

ReactDOM.render(
  
    <Provider store={unistoreStore}>
    <KeyStateProvider>
      <SWRConfig
        value={{
          fetcher: swrFetcher,
        }}>
          <AppInsightsContext.Provider value={reactPlugin}>
        <Routes />
        </AppInsightsContext.Provider>
        {/* below component is moved to AppHeader.tsx */}
        {/* <MessageDisplayer /> */}
      </SWRConfig>
    </KeyStateProvider>
  </Provider>,
  
  document.getElementById('root')
);
