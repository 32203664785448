import { useEffect,useState } from 'react'
import { useLocation } from 'react-router-dom'

const HideBeacon = () => {
  const [refresh, setRefresh] = useState(false)
    const routes = useLocation()
    const entries = performance.getEntriesByType("navigation")
    const performances = entries?.map((item:any) => item.type === 'reload')
     useEffect(() => {
       const timer = setTimeout(() => {
         if (performances[0]) {
           setRefresh(true);
         } else {
           setRefresh(false);
         }
       }, 100);
       return () => {
         clearTimeout(timer);
       };
     }, [performances[0]]);
useEffect(() => {
  const timer = setTimeout(() => {
    const element = document.querySelector('.BeaconFabButtonFrame') as HTMLElement;
    if(routes.pathname === '/propertyreports' || routes.pathname === '/pn' || routes.pathname === '/propertynavigator' ){
      element.style.display ='none'
  }else {
    element.style.display ='inherit'
  }
  },0)
  return () => {
    clearTimeout(timer)
  }
  
},[routes.pathname,refresh])


  return null
}

export default HideBeacon;